import React, {useState} from 'react';
import {
  Box,
  Typography,
  Popover,
  TextField,
  InputAdornment,
} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import './index.css';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {IEmoji} from '@/components/shared/emoji/interfaces/IEmoji.tsx';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import SearchIcons from '@/assets/icons/selectIcons/SearchIcons.svg?react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera} from '@fortawesome/pro-solid-svg-icons';
interface IconOption {
  label: JSX.Element;
  value: string;
  category: string;
}

const ClickableEmojiIcon: React.FC<{
  emoji: IEmoji;
  width: string;
  onClick: () => void;
}> = ({emoji, width, onClick}) => (
  <div onClick={onClick}>
    <EmojiIcon emoji={emoji} width={width} />
  </div>
);

const CardShowcase: React.FC<{title: string}> = ({title}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('recent');
  const [selectedIcon, setSelectedIcon] = useState<IconOption | null>(null);
  const [searchInput, setSearchInput] = useState('');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = (icon: IconOption) => {
    setSelectedIcon(icon);
    handleClose();
  };

  const filterIcons = () => {
    if (searchInput) {
      return WorkflowIconList.filter((icon) =>
        icon.value.toLowerCase().includes(searchInput.toLowerCase()),
      );
    }
    if (selectedCategory === 'recent') {
      return WorkflowIconList;
    }
    return WorkflowIconList.filter(
      (icon) => icon.category === selectedCategory,
    );
  };

  return (
    <>
      <Box
        sx={{marginBottom: '40px', cursor: 'pointer'}}
        aria-describedby={id}
        onClick={handleClick}
      >
        <Box className="new-card-container">
          {selectedIcon ? (
            <EmojiIcon emoji={selectedIcon.label.props.emoji} width="100px" />
          ) : (
            <FontAwesomeIcon
              icon={faCamera}
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                height: '24px',
                color: 'rgba(0, 39, 78, 1)',
              }}
            />
          )}
          <Typography fontSize="16px" fontWeight="600">
            {title}
          </Typography>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            maxHeight: '290px',
          },
        }}
      >
        <div
          style={{
            padding: '8px 12px',
            background: 'rgba(122, 206, 249, 0.4)',
            borderBottom: '1px solid #dfe1e5',
          }}
        >
          <Typography fontSize="12px" color="#003E7C">
            Selecione seu Ícone
          </Typography>
        </div>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="10px"
          margin="5px 20px"
        >
          <ClickableEmojiIcon
            emoji={Emoji.MenuRecents}
            width="18px"
            onClick={() => setSelectedCategory('recent')}
          />
          <ClickableEmojiIcon
            emoji={Emoji.MenuFaces}
            width="18px"
            onClick={() => setSelectedCategory('faces')}
          />
          <ClickableEmojiIcon
            emoji={Emoji.MenuObjects}
            width="18px"
            onClick={() => setSelectedCategory('objects')}
          />
          <ClickableEmojiIcon
            emoji={Emoji.MenuSigns}
            width="18px"
            onClick={() => setSelectedCategory('signs')}
          />
        </Box>
        <TextField
          placeholder="Pesquisar emoji"
          variant="outlined"
          sx={{margin: '0px 20px'}}
          size="small"
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcons />{' '}
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{
            padding: '8px',
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '4px',
          }}
        >
          {filterIcons().map((icon) => (
            <div
              key={icon.value}
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleIconClick(icon)}
            >
              {icon.label}
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default CardShowcase;
