import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';

interface ContainerProps {
  children: ReactNode;
}

const Container: React.FC<ContainerProps> = ({children}) => {
  const {pathname} = useLocation();

  return (
    <Box display="flex" flexDirection="row" sx={{}}>
      <Box
        sx={{
          marginLeft: pathname === '/workflow/new-form' ? '60px' : '130px',
          paddingTop: pathname === '/primeiro-acesso' ? '0px' : '60px',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Container;
