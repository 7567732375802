import http from '../../axios';
import {AxiosResponse} from 'axios';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';

function getAllWorkflowSteps(workflowId: string): Promise<AxiosResponse<WorkflowStepDto[]>> {
  let url = `workflow-step/${workflowId}/list`;

  return http.get<WorkflowStepDto[]>(url);
}

export {
  getAllWorkflowSteps,
};