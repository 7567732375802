import React from 'react';
import {Box} from '@mui/material';
import {
  SectionHeader,
  Checklist,
  FirstAccessFooter,
  MembersSelect,
  MembersInput,
} from '@/components/index';
import Members from '@/assets/icons/tableIcon/Members.svg?react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';
import {useNavigate} from 'react-router-dom';

const NewMembersScreen: React.FC = () => {
  const navigate = useNavigate();
  const options = [
    {label: 'Administrador'},
    {label: 'Membro'},
    {label: 'Visualização'},
  ];

  const teamOptions = [{label: 'Marketing'}, {label: 'Suporte'}];
  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          component="body"
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionHeader
                title="Convide toda a galera!"
                subtitle="Preencha os campos para convidar o pessoal, ou se preferir encaminhe o link com seu time."
              />
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => navigate('/meus-membros')}
                style={{
                  cursor: 'pointer',
                  height: '24px',
                  color: 'rgba(0, 39, 78, 1)',
                }}
              />
            </Box>

            <Box
              component="div"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {' '}
              <div>
                <Box
                  component="div"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                  marginBottom="23px"
                >
                  <MembersInput title="Nome" placeholder="Nome" />
                  <MembersSelect items={options} title="Perfil" />{' '}
                </Box>
                <Box
                  component="div"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                  marginBottom="45px"
                >
                  <MembersInput
                    title="E-mail"
                    placeholder="Digite o e-mail para convidar"
                  />
                  <MembersSelect items={teamOptions} title="Equipe" checkbox />{' '}
                </Box>
                <MembersInput title="Link:" width="620px" link />
              </div>
              <Members />
            </Box>
          </div>

          <FirstAccessFooter
            leftButtonRoute="/meus-membros"
            rightButtonRoute="/meus-membros"
            leftButtonText="Voltar"
            rightButtonText="Avançar"
          />
        </Box>
        <Checklist active="/meus-membros" />
      </Box>
    </>
  );
};

export default NewMembersScreen;
