import React, {useState} from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {CardDraggableComponent} from '@/components/index';

const menuExample = [
  {id: '1', title: 'Financeiro', icon: 'check'},
  {id: '2', title: 'Posts', icon: 'warning'},
  {id: '3', title: 'Suporte', icon: 'trophy'},
  {id: '4', title: 'Teste', icon: 'check'},
];

const WorkflowChoice: React.FC = () => {
  const [cards, setCards] = useState(menuExample);
  const CARD_TYPE_WORKFLOW_CHOICE = 'CARD_TYPE_WORKFLOW_CHOICE';

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const updatedCards = [...cards];
    const [movedCard] = updatedCards.splice(dragIndex, 1);
    updatedCards.splice(hoverIndex, 0, movedCard);
    setCards(updatedCards);
  };

  return (
    <Box
      display="flex"
      component="section"
      flexDirection="column"
      maxWidth="300px"
      height="calc(100vh - 60px)"
      sx={{
        borderLeft: '1px solid rgba(0, 39, 78, 0.2)',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="30px"
      >
        <Typography
          textAlign="center"
          marginBottom="15px"
          fontSize="20px"
          fontWeight="700px"
        >
          Outras equipes
        </Typography>
        <Divider />
        <Typography
          marginY="30px"
          fontSize="12px"
          fontWeight="500"
          textAlign="center"
        >
          Escolha outra equipe para visualizar diferentes fluxos
        </Typography>

        <Box
          component="section"
          display="flex"
          flexWrap="wrap"
          gap="10px"
          justifyContent="space-between"
        >
          {cards.map((item, index) => (
            <Box key={item.id} sx={{width: 'calc(50% - 5px)'}}>
              <CardDraggableComponent
                id={item.id}
                icon={item.icon}
                title={item.title}
                index={index}
                moveCard={moveCard}
                type={CARD_TYPE_WORKFLOW_CHOICE}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WorkflowChoice;
