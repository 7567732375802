import axios from 'axios';
import {v4 as uuidV4} from 'uuid';
import MockAdapter from 'axios-mock-adapter';
import {getCompanyId, getTokenValue} from './utils/token';
import axiosRetry from 'axios-retry';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {WorkflowInputTypeEnum} from '@/models/workflow-input-type.enum.ts';

const baseUrl = import.meta.env.VITE_URL_API_BASE;

const http = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRetry(http, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => error.request.status === 401,
});

http.interceptors.request.use(function (config) {
  const tokenLogin = getTokenValue();
  const companyLogin = getCompanyId();

  config.headers.Authorization = tokenLogin;
  config.headers.companyid = companyLogin;

  return config;
});

var mock = new MockAdapter(http);

const workflows = [
  {
    id: '2323',
    icon: 'trophy',
    title: 'Vendas',
  },
  {
    id: '2324',
    icon: 'rocket-ea',
    title: 'Abertura de Empresa',
  },
];
mock.onGet('/workflow/list').reply(200, workflows);
mock.onGet('/workflow/2324').reply(
  200,
  workflows.find((w) => w.id == '2324'),
);
mock.onGet('/workflow/2323').reply(
  200,
  workflows.find((w) => w.id == '2323'),
);

const generateCard = (data: string, step_id: string) => {
  return {
    id: uuidV4(),
    title: 'Card Loco',
    step_id: step_id,
    values: [
      {
        type: WorkflowInputTypeEnum.Select,
        value: 'Solteiro',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Selecione qual é o seu estado civil?',
          type: WorkflowInputTypeEnum.Select,
          header: false,
          showInCard: true,
          required: false,
          order: 1,
        },
      },
      {
        type: WorkflowInputTypeEnum.Cnpj,
        value: '53.120.234/0001-22',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Insira o seu CNPJ',
          type: WorkflowInputTypeEnum.Cnpj,
          header: false,
          showInCard: false,
          required: false,
          order: 2,
        },
      },
      {
        type: WorkflowInputTypeEnum.Cpf,
        value: '234.312.312-44',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Insira o ceu CPF',
          type: WorkflowInputTypeEnum.Cpf,
          header: false,
          showInCard: true,
          required: false,
          order: 3,
        },
      },
      {
        type: WorkflowInputTypeEnum.PhoneNumber,
        value: '(19) 99736-9584',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Por favor, insira o seu telefone',
          type: WorkflowInputTypeEnum.PhoneNumber,
          header: false,
          showInCard: true,
          required: false,
          order: 4,
        },
      },
      {
        type: WorkflowInputTypeEnum.LongText,
        value:
          'A empreendeaqui é uma plataforma incrível que eu consigo fazer tudo o o que preciso a qualquer hora, 5 estrelas',
        workflowFormInputId: '',
        workflowFormInput: {
          label:
            'Por favor, escreva uma avaliação do serviço de clientes de sucesso',
          type: WorkflowInputTypeEnum.LongText,
          header: false,
          showInCard: false,
          required: false,
          order: 5,
        },
      },
      {
        type: WorkflowInputTypeEnum.Date,
        value: data,
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Data',
          type: WorkflowInputTypeEnum.Date,
          header: true,
          showInCard: false,
          required: false,
          order: 9,
        },
      },
      {
        type: WorkflowInputTypeEnum.Currency,
        value: 'R$ 1.000,00',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Quanto deveríamos cobrar?',
          type: WorkflowInputTypeEnum.Currency,
          header: false,
          showInCard: false,
          required: false,
          order: 8,
        },
      },
      {
        type: WorkflowInputTypeEnum.Email,
        value: 'rahpaviotti@gmail.com',
        workflowFormInputId: '',
        workflowFormInput: {
          label: 'Deixe seu e-mail conosco',
          type: WorkflowInputTypeEnum.Email,
          header: false,
          showInCard: true,
          required: false,
          order: 7,
        },
      },
    ],
  };
};

const steps: Array<WorkflowStepDto> = [
  {
    id: '24565',
    order: 1,
    workflowId: '2323',
    icon: 'negative-cross',
    title: 'Não iniciado',
    allowCreateCard: true,
    cards: [
      generateCard('Bruno Anselmo Dias', '24565'),
      generateCard('Marina Madalena de Menezes', '24565'),
      generateCard('Leandro Batagin', '24565'),
      generateCard('Bruno Anselmo Dias', '24565'),
      generateCard('Marina Madalena de Menezes', '24565'),
    ],
  },
  {
    id: '24566',
    order: 2,
    workflowId: '2323',
    icon: 'warning',
    title: 'Em andamento',
    allowCreateCard: false,
    cards: [
      generateCard('Bruno Anselmo Dias', '24566'),
      generateCard('Marina Madalena de Menezes', '24566'),
      generateCard('Leandro Batagin', '24566'),
    ],
  },
  {
    id: '24567',
    order: 3,
    workflowId: '2323',
    icon: 'rocket-ea',
    title: 'Finalizado',
    allowCreateCard: false,
    cards: [
      generateCard('Bruno Anselmo Dias', '24567'),
      generateCard('Aline Matos Pereira da Silva', '24567'),
      generateCard('Miguel Nascimento', '24567'),
      generateCard('Marina Madalena de Menezes', '24567'),
      generateCard('Leandro Batagin', '24567'),
      generateCard('Aline Matos Pereira da Silva', '24567'),
      generateCard('Aline Matos Pereira da Silva', '24567'),
      generateCard('Aline Matos Pereira da Silva', '24567'),
      generateCard('Aline Matos Pereira da Silva', '24567'),
    ],
  },
];

mock.onGet('/workflow-step/2323/list').reply(200, steps);
mock.onGet('/workflow-step/2324/list').reply(200, steps);

export default http;
