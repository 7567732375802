import React from 'react';
import {Box, TextField, TextFieldProps} from '@mui/material';

interface InputProps
  extends Omit<TextFieldProps, 'variant' | 'size' | 'multiline'> {
  placeholder?: string;
  multiline?: boolean;
}

const Input: React.FC<InputProps> = ({
  multiline = false,
  placeholder,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <TextField
        hiddenLabel
        variant="outlined"
        size="small"
        multiline={multiline}
        placeholder={placeholder}
        fullWidth
        {...props}
      />
    </Box>
  );
};

export default Input;
