import React from 'react';
import {Box, Typography} from '@mui/material';

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({title, subtitle}) => {
  return (
    <Box
      sx={{
        marginBottom: subtitle ? '28px' : '0px',
        marginTop: '40px',
      }}
    >
      <Typography component="h1" variant="h2">
        {title}
      </Typography>
      {subtitle && (
        <Typography
          component="h2"
          variant="body2"
          sx={{wordBreak: 'break-word', maxWidth: '600px'}}
          marginTop="15px"
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default SectionHeader;
