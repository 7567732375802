import React, {useState, useEffect} from 'react';
import {Box, Typography, Checkbox} from '@mui/material';
import ArrowSelect from '@/assets/icons/tableIcon/ArrowSelect.svg?react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSquareChevronDown,
  faSquareChevronUp,
} from '@fortawesome/pro-solid-svg-icons';

interface Item {
  label: string;
}

interface TableSelectProps {
  items?: Item[];
  team?: boolean;
  checkbox?: boolean;
  value?: string;
}

const MembersSelect: React.FC<TableSelectProps> = ({
  items,
  checkbox,
  team,
  value,
}) => {
  const [profile, setProfile] = useState<string>(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setProfile(value || '');
  }, [value]);

  const handleSelectOption = (option: string) => {
    console.log(option, 'clicou');
    setProfile(option);
    setIsOpen(false);
  };

  const profileColor = (type: string) => {
    const colors: {[key: string]: string} = {
      Administrador: 'rgba(255, 209, 121, 1)',
      Membro: 'rgba(122, 206, 249, 1)',
      Visualização: 'rgba(8, 151, 203, 1)',
    };

    return colors[type] || 'rgba(0, 0, 0, 0.1)';
  };

  return (
    <Box
      onClick={() => setIsOpen(!isOpen)}
      sx={{cursor: 'pointer', position: 'relative'}}
    >
      {team ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100px',
          }}
        >
          <Typography color="rgba(0, 0, 0, 1)" fontWeight="400" fontSize="16px">
            {profile ? profile : 'Selecione'}
          </Typography>
          <FontAwesomeIcon
            icon={isOpen ? faSquareChevronUp : faSquareChevronDown}
            className="chevron-icon"
            style={{color: 'rgba(0, 62, 124, 1)'}}
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: profileColor(profile),
            padding: '4px 10px',
            display: 'inline-block',
            borderRadius: '3px',
          }}
        >
          <Typography color="rgba(0, 0, 0, 1)" fontWeight="400" fontSize="16px">
            {profile ? profile : 'Selecione'}
          </Typography>
        </Box>
      )}

      {isOpen && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="absolute" bottom={-17}>
            <ArrowSelect />
          </Box>
          <Box className="custom-select-options">
            {items?.map((option, index) => (
              <Box
                key={index}
                display="flex"
                gap="10px"
                flexDirection="row"
                alignItems="center"
                className="custom-select-option"
                onClick={() => handleSelectOption(option.label)}
              >
                {checkbox && <Checkbox sx={{padding: '0px', margin: '0px'}} />}
                <Typography
                  sx={{
                    color: 'rgba(0, 21, 42, 1)',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MembersSelect;
