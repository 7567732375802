import {BorderColorOutlined} from '@mui/icons-material';
import {ThemeOptions, createTheme} from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00152A', //azul
    },
    secondary: {
      main: '#00B050', //verde
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
    },
    overline: {
      fontSize: '0.625rem',
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 5,
          padding: '10px 22px',
          fontSize: '18px',
        },
        contained: {
          backgroundColor: '#00B050',
          '&:hover': {
            backgroundColor: 'rgba(0, 176, 80, 0.8)',
          },
        },
        outlined: {
          borderColor: '#00152A',
          color: '#00152A',
          '&:hover': {
            backgroundColor: 'rgba(0, 21, 42, 0.2)',
            borderColor: '#00152A',
          },
        },
        text: {
          color: '#0897CB',
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: {variant: 'standard'},
          style: {
            '& .MuiInput-underline:before': {
              borderBottom: '1px solid #7F92A6',
            },
            '& .MuiInput-underline:after': {
              borderBottom: '1px solid #7F92A6',
            },
            '& .MuiInput-underline:hover:before': {
              borderBottom: '1px solid #7F92A6',
            },
          },
        },
        {
          props: {variant: 'outlined'},
          style: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                color: '#00274E',
              },
              '&:hover fieldset': {
                color: '#00274E',
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                color: '#00152A',
                borderColor: '#B2BAC2',
              },
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#0897CB',
          fontSize: '1.125rem',
          fontWeight: 600,
          color: '#FFFFFF',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgba(8, 151, 203, 0.8)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.scrollable-box': {
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 200px)',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#E2E8F1',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#E2E8F1',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#0897CB',
          },
          '&.Mui-checked': {
            color: '#0897CB',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px',
          fontSize: '14px',
        },
        head: {
          backgroundColor: 'rgba(0, 62, 124, 1)',
          height: '37px',
        },
        body: {
          padding: '0px',
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
