import React, {useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
} from '@mui/material';
import {TableSelect} from '@/components/index';

interface DataTableProps {
  itens?: RowData[];
}

interface RowData {
  id: number;
  name: string;
  email: string;
  team: string;
  profile: string;
}

const teams = [{label: 'Suporte'}, {label: 'Marketing'}, {label: 'Contábil'}];
const profiles = [
  {label: 'Administrador'},
  {label: 'Membro'},
  {label: 'Visualização'},
];

const initialData: RowData[] = [];

export const DataTable: React.FC<DataTableProps> = ({itens = initialData}) => {
  const [data, setData] = useState<RowData[]>(itens);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id],
    );
  };

  return (
    <TableContainer component={Paper} elevation={0} sx={{overflow: 'visible'}}>
      <Table>
        <TableHead
          sx={{
            backgroundColor: 'rgba(0, 62, 124, 1)',
            height: '37px',
          }}
        >
          <TableRow>
            {data.length > 0 && (
              <TableCell sx={{padding: '0px'}}>
                <Checkbox sx={{color: '#ffffff'}} />
              </TableCell>
            )}
            <TableCell
              sx={{
                color: '#ffffff',
                fontWeight: '600',
                fontSize: '16px',
                padding: '0px',
                paddingLeft: data.length > 0 ? '0px' : '10px',
              }}
            >
              Nome
            </TableCell>
            <TableCell
              sx={{
                color: '#ffffff',
                fontWeight: '600',
                fontSize: '16px',
                padding: '0px',
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                color: '#ffffff',
                fontWeight: '600',
                fontSize: '16px',
                padding: '0px',
              }}
            >
              Equipe
            </TableCell>
            <TableCell
              align="center"
              sx={{
                color: '#ffffff',
                fontWeight: '600',
                fontSize: '16px',
                padding: '0px',
              }}
            >
              Perfil
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itens.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{textAlign: 'center', padding: '16px'}}
              >
                Não há registros ainda
              </TableCell>
            </TableRow>
          ) : (
            itens.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 1)',
                    fontWeight: '400',
                    fontSize: '16px',
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 1)',
                    fontWeight: '400',
                    fontSize: '16px',
                  }}
                >
                  {row.email}
                </TableCell>

                <TableCell sx={{position: 'relative', cursor: 'pointer'}}>
                  <TableSelect items={teams} team checkbox value={row.team} />
                </TableCell>

                <TableCell
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                  align="center"
                >
                  <TableSelect items={profiles} value={row.profile} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
