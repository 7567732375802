import React from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface NumberProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Number: React.FC<NumberProps> = ({
  title = 'Número',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input type="number" />
      </FormContainer>
    </Box>
  );
};

export default Number;
