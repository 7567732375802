import React, {useState, useCallback, useEffect} from 'react';
import {Box, Button} from '@mui/material';
import {useDrop, DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {
  Submenu,
  EditComponent,
  FormDraggableComponent,
  EmptyForm,
  FormPlaceholder,
  FormHeader,
} from '@/components/index';
import {ItemTypes} from '@/types/ItemTypes';

interface ComponentData {
  id: string;
  Component: React.ElementType;
  title?: string;
  colSpan?: number;
}
interface DragItem {
  type: React.ElementType;
  label: string;
  colSpan: number;
}

const NewForm: React.FC = () => {
  const [components, setComponents] = useState<ComponentData[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentComponentId, setCurrentComponentId] = useState<string | null>(
    null,
  );
  const [currentTitle, setCurrentTitle] = useState<string>('');
  const [isCopy, setIsCopy] = useState(false);
  const [placeholderIndex, setPlaceholderIndex] = useState<number | null>(null);
  const generateId = (): string =>
    `id_${Math.random().toString(36).substr(2, 9)}`;

  //função que adiciona o componente escolhido no array de componentes
  const handleSelectComponent = useCallback(
    (component: React.ElementType, label: string, colSpan: number) => {
      setComponents((prevComponents) => [
        ...prevComponents,
        {
          id: generateId(),
          Component: component,
          title: label,
          colSpan: colSpan,
        },
      ]);
      console.log(label, colSpan);
    },
    [],
  );

  const testeApi = () => {};
  // função pra edição do componente
  const handleEdit = (id: string) => {
    const component = components.find((c) => c.id === id);
    if (component) {
      setCurrentComponentId(id);
      setCurrentTitle(component.title || '');
      setModalOpen(true);
      setIsCopy(false);
    }
  };

  // Função que salva as alterações
  const handleSave = (newTitle: string) => {
    if (isCopy) {
      const componentToCopy = components.find(
        (c) => c.id === currentComponentId,
      );
      if (componentToCopy) {
        setComponents([...components, {...componentToCopy, title: newTitle}]);
      }
    } else {
      setComponents(
        components.map((c) =>
          c.id === currentComponentId ? {...c, title: newTitle} : c,
        ),
      );
    }
    setCurrentComponentId(null);
    setCurrentTitle('');
    setModalOpen(false);
    setIsCopy(false);
  };

  const handleCopy = (id: string) => {
    const component = components.find((c) => c.id === id);
    setCurrentComponentId(id);
    setCurrentTitle(component ? component.title || '' : '');
    setIsCopy(true);
  };

  // deletando componente do array
  const handleDelete = (id: string) => {
    setComponents(components.filter((c) => c.id !== id));
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // função pra movimentar o componente que já está array
  const moveComponent = (dragIndex: number, hoverIndex: number) => {
    const dragComponent = components[dragIndex];
    const updatedComponents = [...components];
    updatedComponents.splice(dragIndex, 1);
    updatedComponents.splice(hoverIndex, 0, dragComponent);
    setComponents(updatedComponents);
    setPlaceholderIndex(null);
  };

  //lógica de drop
  const [{isOver}, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    hover: (item: DragItem, monitor) => {
      const hoverIndex = components.length;
      setPlaceholderIndex(hoverIndex);
    },
    drop: (item: DragItem, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      const hoverIndex =
        placeholderIndex !== null ? placeholderIndex : components.length;
      handleSelectComponent(item.type, item.label, item.colSpan);
      setPlaceholderIndex(null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  //função que pega o tamanho que o componente pode ocupar na tela
  const getFlexValue = (colSpan?: number): string => {
    const effectiveColSpan = colSpan ?? 6;

    if (effectiveColSpan === 12) {
      return '1 1 100%';
    }
    return '1 1 calc(50% - 10px)';
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        marginTop="50px"
        flexDirection="row"
        sx={{backgroundColor: '#EEFBFF'}}
      >
        <FormHeader />
        <Submenu />
        <Box
          ref={drop}
          className="submenu-board"
          component="div"
          width="650px"
          marginLeft="100px"
          marginTop="40px"
          borderRadius="5px 5px 0px 0px"
          sx={{
            backgroundColor: isOver ? '#e0f7fa' : '#FFFFFF',
            display: 'flex',
            justifyContent: components.length === 0 ? 'center' : undefined,
            alignItems: components.length === 0 ? 'center' : undefined,
            flexWrap: 'wrap',
            gap: '10px',
            padding: '20px',
          }}
        >
          {components.length > 0 ? (
            components.map((componentData, index) => (
              <React.Fragment key={`${componentData.id}-${index}`}>
                {placeholderIndex === index && <FormPlaceholder />}

                <Box
                  flex={getFlexValue(componentData.colSpan)}
                  display="flex"
                  flexDirection="column"
                >
                  <FormDraggableComponent
                    colSpan={componentData.colSpan}
                    id={componentData.id}
                    index={index}
                    Component={componentData.Component}
                    moveComponent={moveComponent}
                    onEdit={() => handleEdit(componentData.id)}
                    onCopy={() => handleCopy(componentData.id)}
                    onDelete={() => handleDelete(componentData.id)}
                  />
                </Box>
              </React.Fragment>
            ))
          ) : (
            <EmptyForm />
          )}
          {isOver && placeholderIndex === components.length && (
            <FormPlaceholder />
          )}
        </Box>
        <Box position="fixed" bottom={40} right={25}>
          <Button variant="contained" color="primary">
            Concluído
          </Button>
        </Box>
        <EditComponent
          open={modalOpen}
          onClose={handleModalClose}
          onSave={handleSave}
          initialTitle={currentTitle}
        />
      </Box>
    </DndProvider>
  );
};

export default NewForm;
