import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface EmailProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Email: React.FC<EmailProps> = ({
  title = 'E-mail',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input />
      </FormContainer>
    </Box>
  );
};

export default Email;
