import React from 'react';
import './index.css';
import {Emoji, FindEmojiElement} from '@/components/shared/emoji/emoji.tsx';
import {IEmoji} from '@/components/shared/emoji/interfaces/IEmoji.tsx';

function EmojiIcon({emoji, width}: {emoji: string | IEmoji; width?: string}) {
  let element: React.JSX.Element;
  if (typeof emoji === 'string') {
    element = FindEmojiElement(emoji);
  } else {
    element = emoji.element;
  }

  return (
    <div style={{width}} className={'emoji-icon'}>
      {element}
    </div>
  );
}

export {EmojiIcon, Emoji};
