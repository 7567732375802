export function getTokenValue(): string {
    const value = localStorage.getItem("eaqui-token");
    return `Bearer ${value}`;
}

export function getCompanyId(): string {
    return localStorage.getItem("eaqui-company");
}

export function getIsUserContabilidade(): string {
    return localStorage.getItem("eaqui-contabilidade");
}