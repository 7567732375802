import {faMoneyBill1Wave} from '@fortawesome/pro-solid-svg-icons';

const Input = () => {
  return <></>;
};

const CardIcon = faMoneyBill1Wave;

export default {
  CardIcon,
  Input,
};
