import React, {useState} from 'react';
import {Box, Typography, Avatar, Popover, Divider} from '@mui/material';
import './Header.css';
import {useNavigate, useLocation, matchPath} from 'react-router-dom';
import {AnimatedHeaderItem} from '@/components/animatedHeaderItem/AnimatedHeaderItem';
import CircleQuestion from '@/assets/svg/header/Circle-question.svg';
import Heart from '@/assets/svg/header/Heart.svg';
import User from '@/assets/svg/header/menu/User.svg';
import Company from '@/assets/svg/header/menu/Company.svg';
import Team from '@/assets/svg/header/menu/Team.svg';
import Members from '@/assets/svg/header/menu/Members.svg';
import Streams from '@/assets/svg/header/menu/Streams.svg';
import Logout from '@/assets/svg/header/menu/Logout.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import {SearchInput} from '@/components/index';

const headerItems = [
  {id: 1, label: 'INÍCIO', route: '/'},
  {id: 2, label: 'EMPRESAS', route: '/minhas-empresas'},
  {id: 3, label: 'EQUIPES', route: '/minhas-equipes'},
  {id: 4, label: 'MEMBROS', route: '/meus-membros'},
  {id: 5, label: 'FLUXOS', route: '/meus-fluxos'},
];

const menuItems = [
  {id: 1, label: 'Meu perfil', route: '/meu-perfil', icon: User},
  {id: 2, label: 'Minhas empresas', route: '/minhas-empresas', icon: Company},
  {id: 3, label: 'Equipes', route: '/minhas-equipes', icon: Team},
  {id: 4, label: 'Membros', route: '/meus-membros', icon: Members},
  {id: 5, label: 'Fluxos', route: '/meus-fluxos', icon: Streams},
];

const FormHeader: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState(1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const isKanbanRoute = matchPath('/workflow/:id/kanban', location.pathname);

  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (index: any, route: string) => {
    setActiveMenu(index);
    navigate(route);
  };

  return (
    <Box className="header-container">
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        gap="24px"
        marginLeft="100px"
      >
        {isKanbanRoute ? (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <Typography className={`header-item active`}>Vendas</Typography>
              <FontAwesomeIcon
                icon={isOpen ? faChevronUp : faChevronDown}
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  cursor: 'pointer',
                  height: '12px',
                  color: '#FF6407',
                }}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              width="30px"
              height="30px"
              borderRadius="50%"
              border="1px solid rgba(0, 39, 78, 0.30)"
              color="#ffffff"
            />
            <Typography className={`header-item active`}>Vendas</Typography>
          </>
        ) : (
          <>
            {headerItems.map((item) => (
              <Typography
                key={item.id}
                className={`header-item ${activeMenu === item.id && 'active'}`}
                onClick={() => handleMenuClick(item.id, item.route)}
              >
                {item.label}
              </Typography>
            ))}
          </>
        )}
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        gap="30px"
        marginRight="40px"
        alignItems="center"
      >
        <SearchInput />
        <AnimatedHeaderItem icon={Heart} text="Indique aqui" />
        <AnimatedHeaderItem icon={CircleQuestion} text="Ajuda" />
        <Avatar
          component="button"
          sx={{
            bgcolor: '#98C6E0',
            cursor: 'pointer',
            height: '30px',
            width: '30px',
          }}
          aria-describedby={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            handleClick(event)
          }
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#001B37',
            color: '#FFFFFF',
            paddingX: '25px',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          component="div"
          gap="20px"
          marginTop="20px"
        >
          <Avatar
            sx={{
              bgcolor: '#98C6E0',
              cursor: 'pointer',
              height: '24px',
              width: '24px',
            }}
          />
          <Typography
            sx={{color: '#00C1EB', fontWeight: '400', fontSize: '16px'}}
          >
            CAFÉ DO PONTO
          </Typography>
        </Box>
        <Divider color="#FFFFFF" sx={{marginY: '20px', width: '100%'}} />
        {menuItems.map((item) => (
          <Box
            key={item.id}
            display="flex"
            flexDirection="row"
            alignItems="center"
            component="a"
            gap="24px"
            marginBottom="20px"
            onClick={() => navigate(item.route)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <img src={item.icon} width={16} />
            <Typography fontSize="16px" fontWeight={300}>
              {item.label}
            </Typography>
          </Box>
        ))}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          component="div"
          gap="24px"
          marginY="27px"
        >
          <img src={Logout} width={16} />
          <Typography fontSize="16px" fontWeight={300}>
            Sair do sistema
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default FormHeader;
