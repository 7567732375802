import { ReactNode, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

type Props = {
    children: ReactNode
}

function KeycloakAuth({ children }: Props) {
    const { keycloak, initialized } = useKeycloak();
    useEffect(() => {
        const checkAuthentication = async () => {
            if (!keycloak.authenticated) {
                return await keycloak.login();
            }

            return ({ children })
        };

        if (initialized) {
            checkAuthentication();
        }
    }, [keycloak, initialized]);

    if (keycloak.authenticated) {
        localStorage.setItem('eaqui-token', keycloak.token);
        localStorage.setItem('eaqui-company', keycloak.tokenParsed.id_enterprise);        
        localStorage.setItem('eaqui-contabilidade', keycloak.tokenParsed.access_contabilidade);
        document.cookie = `token=${keycloak.token}`
        return (
            <div>
                {children}
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default KeycloakAuth;