import {Typography, Box} from '@mui/material';
import {WorkflowStepCardDto} from '@/models/workflow-step-card.dto.ts';
import {WorkflowKanbanCardValue} from '@/pages/workflow/kanban/components/workflowKanbanCardValue';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faCalendarDays} from '@fortawesome/pro-solid-svg-icons';
import responsibleImage from '@/assets/images/Responsible.jpg';

// esse é cada um dos cards
interface WorkflowKanbanCardProps {
  card?: WorkflowStepCardDto;
}

export const WorkflowKanbanCard = ({card}: WorkflowKanbanCardProps) => {
  let cardValues = card.values
    .filter((v) => v.workflowFormInput.showInCard)
    .sort((a, b) => a.workflowFormInput.order - b.workflowFormInput.order);
  let headerValue = card.values.filter((v) => v.workflowFormInput.header).pop();

  return (
    <Box className="workflow-board-card">
      <Box
        style={{
          paddingBottom: '8px',
        }}
      >
        <Typography variant="body1" fontWeight="700">
          {headerValue.value}
        </Typography>
      </Box>
      <Box>
        {cardValues.map((value) => (
          <WorkflowKanbanCardValue cardValue={value} />
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="10px"
        justifyContent="space-between"
      >
        <Box
          width="30px"
          height="30px"
          borderRadius="50%"
          border="1px solid rgba(0, 39, 78, 0.30)"
          style={{
            backgroundImage: `url(${responsibleImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          gap="5px"
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={faCalendarDays}
            style={{
              cursor: 'pointer',
              height: '14px',
              color: '#00274E',
            }}
          />
          <Typography color="#00274E">SET</Typography>
        </Box>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          gap="5px"
          alignItems="center"
          border="0.7px solid rgba(0, 21, 42, 0.20)"
          padding="3px 15px"
          borderRadius="70px"
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              cursor: 'pointer',
              height: '14px',
              color: '#00274E',
            }}
          />
          <Typography color="#00274E">Tag</Typography>
        </Box>
      </Box>
    </Box>
  );
};
