import React, {useState} from 'react';
import Select, {components, MenuProps, ActionMeta} from 'react-select';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import {Typography, Box} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {IEmoji} from '@/components/shared/emoji/interfaces/IEmoji.tsx';

interface Props {
  placeholder: string;
  className?: string;
  onChange: (value: any, actionMeta: ActionMeta<any>) => void;
  value: any;
  id: string;
  loading?: boolean;
}

const ClickableEmojiIcon: React.FC<{
  emoji: IEmoji;
  width: string;
  onClick: () => void;
}> = ({emoji, width, onClick}) => (
  <div onClick={onClick}>
    <EmojiIcon emoji={emoji} width={width} />
  </div>
);

const CustomMenu: React.FC<MenuProps<any, false>> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('recent');

  const filterIcons = () => {
    if (searchInput) {
      return WorkflowIconList.filter((icon) =>
        icon.value.toLowerCase().includes(searchInput.toLowerCase()),
      );
    }
    if (selectedCategory === 'recent') {
      return WorkflowIconList;
    }
    return WorkflowIconList.filter(
      (icon) => icon.category === selectedCategory,
    );
  };

  return (
    <components.Menu {...props}>
      <div
        style={{
          padding: '8px 12px',
          background: 'rgba(122, 206, 249, 0.4)',
          borderBottom: '1px solid #dfe1e5',
        }}
      >
        <Typography fontSize="12px" color="#003E7C">
          Selecione seu Ícone
        </Typography>
      </div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="10px"
        margin="5px 20px"
      >
        <ClickableEmojiIcon
          emoji={Emoji.MenuRecents}
          width="18px"
          onClick={() => setSelectedCategory('recent')}
        />
        <ClickableEmojiIcon
          emoji={Emoji.MenuFaces}
          width="18px"
          onClick={() => setSelectedCategory('faces')}
        />
        <ClickableEmojiIcon
          emoji={Emoji.MenuObjects}
          width="18px"
          onClick={() => setSelectedCategory('objects')}
        />
        <ClickableEmojiIcon
          emoji={Emoji.MenuSigns}
          width="18px"
          onClick={() => setSelectedCategory('signs')}
        />
      </Box>

      <div
        style={{
          padding: '8px',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gap: '4px',
        }}
      >
        {filterIcons().map((icon) => (
          <div
            key={icon.value}
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() =>
              props.selectProps.onChange(icon, {
                action: 'select-option',
                option: icon,
              })
            }
          >
            {icon.label}
          </div>
        ))}
      </div>
    </components.Menu>
  );
};

export default function SelectWorkflowIcon({
  onChange,
  className,
  placeholder,
  value,
  loading,
}: Props) {
  const onChangeInternal = (e: any, actionMeta: ActionMeta<any>) => {
    const valueSelected = WorkflowIconList.find(({value}) => value === e.value);
    onChange(valueSelected, actionMeta);
  };

  return (
    <Select
      className={className}
      styles={{
        menu: (base) => ({
          ...base,
          margin: 0,
          padding: 0,
          width: '250px',
        }),
        menuList: (base) => ({
          ...base,
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          padding: '8px',
          gap: '4px',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: '40px',
          borderColor: '#dfe1e5',
          boxShadow: state.isFocused ? '0 0 0 1px #4285f4' : '',
          '&:hover': {
            borderColor: '#dfe1e5',
          },
        }),
        option: (base, {isFocused}) => ({
          ...base,
          backgroundColor: isFocused ? '#f1f3f4' : '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '25px',
          fontSize: '20px',
          padding: '10px',
          cursor: 'pointer',
          '&:active': {
            backgroundColor: '#f1f3f4',
          },
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: '4px',
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: '16px',
        }),
      }}
      components={{
        Menu: CustomMenu,
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder}
      options={WorkflowIconList}
      value={value}
      onChange={onChangeInternal}
      isLoading={loading}
    />
  );
}
