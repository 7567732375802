import React, {useRef, useState} from 'react';
import {Box, Modal, Typography, Button, Slider} from '@mui/material';
import Cropper, {ReactCropperElement} from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  image?: string;
  onImageCropped: (croppedImage: string) => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  image,
  onImageCropped,
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [zoom, setZoom] = useState(1);

  const handleClose = () => {
    onClose();
  };

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      onImageCropped(croppedImage);
    }
  };

  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    if (cropperRef.current?.cropper) {
      const zoomValue = Array.isArray(newValue) ? newValue[0] : newValue;
      setZoom(zoomValue);
      cropperRef.current.cropper.zoomTo(zoomValue);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#fff',
          borderRadius: '5px',
          paddingY: '10px',
          position: 'relative',
          maxWidth: '500px',
          maxHeight: '80vh',
        }}
      >
        <Box
          component="header"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="5px"
          marginX="15px"
        >
          <Typography fontSize="18px" fontWeight="700">
            Atualizar foto
          </Typography>
          <FontAwesomeIcon
            icon={faClose}
            onClick={handleClose}
            style={{
              cursor: 'pointer',
              height: '24px',
              color: 'rgba(0, 39, 78, 1)',
            }}
          />
        </Box>
        <Box
          flex="1"
          sx={{
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Cropper
            src={image || ''}
            style={{height: 'auto', width: '100%'}}
            initialAspectRatio={1}
            guides={false}
            ref={cropperRef}
            viewMode={1}
          />
        </Box>
        <Box
          sx={{
            marginTop: '20px',
            marginX: '20px',
          }}
        >
          <Slider
            value={zoom}
            size="small"
            min={0.1}
            max={1}
            step={0.01}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
            aria-labelledby="zoom-slider"
          />
        </Box>
        <Box
          component="footer"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="20px"
          marginX="20px"
        >
          <Button
            variant="outlined"
            sx={{
              padding: '10px 22px',
              fontSize: '18px',
            }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{
              padding: '10px 22px',
              fontSize: '18px',
            }}
            onClick={onCrop}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
