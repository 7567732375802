import React, {useState} from 'react';
import './Search.css';

const SearchInput: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleInput = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        className={`search-input ${isExpanded ? 'expanded' : ''}`}
        placeholder="Pesquisar"
      />
      <button className="search-button" onClick={toggleInput}>
        <i className="fas fa-search"></i>
      </button>
    </div>
  );
};

export default SearchInput;
