import React from 'react';
import {Box, Modal, Button, Typography, Divider} from '@mui/material';
import InfoGif from '@/assets/svg/info.gif';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';

interface ConfirmationModalProps {
  modalTitle: string;
  bodyTitle: string;
  bodySubtitle: string;
  rightButton: string;
  open: boolean;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalTitle,
  bodyTitle,
  bodySubtitle,
  rightButton,
  open,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            maxWidth: '550px',
            borderRadius: '8px',
            outline: 'none',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="18px"
          >
            <Typography fontWeight="700" fontSize="20px" component="h2">
              {modalTitle}
            </Typography>
            <FontAwesomeIcon
              icon={faClose}
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                height: '24px',
                color: 'rgba(0, 39, 78, 1)',
              }}
            />
          </Box>
          <Divider />
          <Box padding="20px 40px" sx={{textAlign: 'center'}}>
            <img src={InfoGif} alt="Informação" width="100px" />
            <Typography component="p" mt={2} fontWeight="700" fontSize="24px">
              {bodyTitle}
            </Typography>
            <Typography mt={1} fontWeight="500" fontSize="18px">
              {bodySubtitle}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="40px"
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: 'rgba(0, 21, 42, 0.5)',
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleClose}>
              {rightButton}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
