import React from 'react';
import {Card, CardContent, Typography, Box} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import {WorkflowDto} from '@/models/workflow.dto.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {faGrid2} from '@fortawesome/pro-solid-svg-icons';
import './index.css';
interface WorkflowCardProps {
  onClick?: () => void;
  workflow: WorkflowDto;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({onClick, workflow}) => {
  const animatedClass = onClick ? 'animated' : '';
  const isMoreIcon = workflow.icon === 'mais';

  return (
    <Card
      className={`workflow-card ${animatedClass}`}
      onClick={onClick}
      sx={{
        position: 'relative',
        '&:hover .icon-box': {
          opacity: 1,
        },
      }}
      aria-label={workflow.title}
    >
      {!isMoreIcon && (
        <Box
          className="icon-box"
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          sx={{
            position: 'absolute',
            top: 10,
            left: 10,
            opacity: 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          <FontAwesomeIcon
            icon={faGrid2}
            style={{
              cursor: 'pointer',
              height: '13px',
              color: 'rgba(0, 39, 78, 1)',
            }}
          />
          <FontAwesomeIcon
            icon={faPen}
            style={{
              cursor: 'pointer',
              height: '13px',
              color: 'rgba(0, 39, 78, 1)',
              marginRight: '20px',
            }}
          />
        </Box>
      )}

      <CardContent className="card-container">
        <EmojiIcon emoji={workflow.icon} />
        <Typography variant="body1" align="center">
          {workflow.title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WorkflowCard;
