import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface AttachFileProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const AttachFile: React.FC<AttachFileProps> = ({
  title = 'Anexar arquivo',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input />
      </FormContainer>
    </Box>
  );
};

export default AttachFile;
