import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface CpfProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Cpf: React.FC<CpfProps> = ({title = 'CPF', onEdit, onCopy, onDelete}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input placeholder="000.000.000-00" />
      </FormContainer>
    </Box>
  );
};

export default Cpf;
