import React from 'react';

import './AnimatedHeaderItem.css';

export const AnimatedHeaderItem = ({text, icon}: {text: string; icon: any}) => {
  const style = {'--text': text} as React.CSSProperties;

  return (
    <div data-text={text} className="header-tooltip" style={style}>
      <img src={icon} className="header-tooltip svg" />
    </div>
  );
};
