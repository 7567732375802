import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface TelephoneProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Telephone: React.FC<TelephoneProps> = ({
  title = 'Telefone',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input placeholder="(00) 00000-0000" />
      </FormContainer>
    </Box>
  );
};

export default Telephone;
