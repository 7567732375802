import {IEmojiList} from '@/components/shared/emoji/interfaces/IEmojiList.tsx';
import SvgCheck from '@/assets/icons/emoji-check.svg?react';
import SvgWarning from '@/assets/icons/emoji-warning.svg?react';
import SvgNegativeCross from '@/assets/icons/emoji-negative-cross.svg?react';
import SvgHeart from '@/assets/icons/emoji-heart.svg?react';
import SvgTrophy from '@/assets/icons/emoji-trophy.svg?react';
import SvgFire from '@/assets/icons/emoji-fire.svg?react';
import SvgSmileEyes from '@/assets/icons/emoji-smile-eyes.svg?react';
import SvgSunglasses from '@/assets/icons/emoji-sunglasses.svg?react';
import SvgThinking from '@/assets/icons/emoji-thinking.svg?react';
import SvgCrying from '@/assets/icons/emoji-crying.svg?react';
import SvgTriumph from '@/assets/icons/emoji-triumph.svg?react';
import SvgPouting from '@/assets/icons/emoji-pouting.svg?react';
import SvgBarChart from '@/assets/icons/emoji-bar-chart.svg?react';
import SvgMoneyBag from '@/assets/icons/emoji-money-bag.svg?react';
import SvgThumbsUp from '@/assets/icons/emoji-thumbs-up.svg?react';
import SvgClapping from '@/assets/icons/emoji-clapping.svg?react';
import SvgBell from '@/assets/icons/emoji-bell.svg?react';
import SvgBasketball from '@/assets/icons/emoji-basketball.svg?react';
import SvgMedal from '@/assets/icons/emoji-medal.svg?react';
import SvgTools from '@/assets/icons/emoji-tools.svg?react';
import SvgRocketEa from '@/assets/icons/emoji-rocket-ea.svg?react';
import React from 'react';
//novos icones
import SvgMais from '@/assets/svg/mais.svg?react';

import SvgRocket from '@/assets/icons/emoji-rocket.svg?react';
import EmojiAlien from '@/assets/icons/newIcons/emoji-alien.svg?react';
import EmojiBallotBox from '@/assets/icons/newIcons/emoji-ballot-box.svg?react';
import EmojiBarChart from '@/assets/icons/newIcons/emoji-bar-chart.svg?react';
import EmojiBell from '@/assets/icons/newIcons/emoji-bell.svg?react';
import EmojiBomb from '@/assets/icons/newIcons/emoji-bomb.svg?react';
import EmojiChequeredFlag from '@/assets/icons/newIcons/emoji-chequered-flag.svg?react';
import EmojiCrossmark from '@/assets/icons/newIcons/emoji-crossmark.svg?react';
import EmojiDirectHit from '@/assets/icons/newIcons/emoji-direct-hit.svg?react';
import EmojiEnvelope from '@/assets/icons/newIcons/emoji-envelope.svg?react';
import EmojiExclamationMark from '@/assets/icons/newIcons/emoji-exclamation-mark.svg?react';
import EmojiExplodingHead from '@/assets/icons/newIcons/emoji-exploding-head.svg?react';
import EmojiEyes from '@/assets/icons/newIcons/emoji-eyes.svg?react';
import EmojiFacingUp from '@/assets/icons/newIcons/emoji-facing-up.svg?react';
import EmojiFlexedBiceps from '@/assets/icons/newIcons/emoji-flexed-biceps.svg?react';
import EmojiGear from '@/assets/icons/newIcons/emoji-gear.svg?react';
import EmojiGlobe from '@/assets/icons/newIcons/emoji-globe.svg?react';
import EmojiGlowingStar from '@/assets/icons/newIcons/emoji-glowing-star.svg?react';
import EmojiGreenCircle from '@/assets/icons/newIcons/emoji-green-circle.svg?react';
import EmojiHammer from '@/assets/icons/newIcons/emoji-hammer.svg?react';
import EmojiHeavyCheckMark from '@/assets/icons/newIcons/emoji-heavy-check-mark.svg?react';
import EmojiHeavyDollarSign from '@/assets/icons/newIcons/emoji-heavy-dollar-sign.svg?react';
import EmojiHeavyPlusSign from '@/assets/icons/newIcons/emoji-heavy-plus-sign.svg?react';
import EmojiHignBrowns from '@/assets/icons/newIcons/emoji-high-browns.svg?react';
import EmojiHighVoltage from '@/assets/icons/newIcons/emoji-high-voltage.svg?react';
import EmojiHourglass from '@/assets/icons/newIcons/emoji-hourglass.svg?react';
import EmojiKey from '@/assets/icons/newIcons/emoji-key.svg?react';
import EmojiLight from '@/assets/icons/newIcons/emoji-light.svg?react';
import EmojiMemo from '@/assets/icons/newIcons/emoji-memo.svg?react';
import EmojiMoneyBag from '@/assets/icons/newIcons/emoji-money-bag.svg?react';
import EmojiNeutralFace from '@/assets/icons/newIcons/emoji-neutral-face.svg?react';
import EmojiNoEntry from '@/assets/icons/newIcons/emoji-no-entry.svg?react';
import EmojiOpenBook from '@/assets/icons/newIcons/emoji-open-book.svg?react';
import EmojiPackage from '@/assets/icons/newIcons/emoji-package.svg?react';
import EmojiPencil from '@/assets/icons/newIcons/emoji-pencil.svg?react';
import EmojiPoutingFace from '@/assets/icons/newIcons/emoji-pouting-face.svg?react';
import EmojiPushPin from '@/assets/icons/newIcons/emoji-pushpin.svg?react';
import EmojiRedCircle from '@/assets/icons/newIcons/emoji-red-circle.svg?react';
import EmojiRoundPushpin from '@/assets/icons/newIcons/emoji-round-pushpin.svg?react';
import EmojiShield from '@/assets/icons/newIcons/emoji-shield.svg?react';
import EmojiShushingFace from '@/assets/icons/newIcons/emoji-shushing-face.svg?react';
import EmojiSkull from '@/assets/icons/newIcons/emoji-skull.svg?react';
import EmojiSlightlyEsmilingFace from '@/assets/icons/newIcons/emoji-slightly-smiling-face.svg?react';
import EmojiSmile from '@/assets/icons/newIcons/emoji-smile.svg?react';
import EmojiSportsMedal from '@/assets/icons/newIcons/emoji-sports-medal.svg?react';
import EmojiStarStruck from '@/assets/icons/newIcons/emoji-star-struck.svg?react';
import EmojiStopSign from '@/assets/icons/newIcons/emoji-stop-sign.svg?react';
import EmojiStopWatch from '@/assets/icons/newIcons/emoji-stopwatch.svg?react';
import EmojiTelephoneReceiver from '@/assets/icons/newIcons/emoji-telephone-receiver.svg?react';
import EmojiTrophy from '@/assets/icons/newIcons/emoji-trophy.svg?react';
import EmojiWarning from '@/assets/icons/newIcons/emoji-warning.svg?react';
import EmojiWastebasket from '@/assets/icons/newIcons/emoji-wastebasket.svg?react';
import EmojiWhiteCheck from '@/assets/icons/newIcons/emoji-white-check.svg?react';
import EmojiWhiteMediumStar from '@/assets/icons/newIcons/emoji-white-medium-star.svg?react';
import EmojiWritingHand from '@/assets/icons/newIcons/emoji-writing-hand.svg?react';
import EmojiMenuFaces from '@/assets/icons/newIcons/emoji-menu-faces.svg?react';
import EmojiMenuObjects from '@/assets/icons/newIcons/emoji-menu-objects.svg?react';
import EmojiMenuRecents from '@/assets/icons/newIcons/emoji-menu-recents.svg?react';
import EmojiMenuSigns from '@/assets/icons/newIcons/emoji-menu-signs.svg?react';
//submenu
import EmojiAttach from '@/assets/icons/submenuIcons/emoji-attach.svg?react';
import EmojiCnpj from '@/assets/icons/submenuIcons/emoji-cnpj.svg?react';
import EmojiCoin from '@/assets/icons/submenuIcons/emoji-coin.svg?react';
import EmojiCpf from '@/assets/icons/submenuIcons/emoji-cpf.svg?react';
import EmojiDate from '@/assets/icons/submenuIcons/emoji-date.svg?react';
import EmojiEmail from '@/assets/icons/submenuIcons/emoji-email.svg?react';
import EmojiHeader from '@/assets/icons/submenuIcons/emoji-header.svg?react';
import EmojiLink from '@/assets/icons/submenuIcons/emoji-link.svg?react';
import EmojiLongText from '@/assets/icons/submenuIcons/emoji-longText.svg?react';
import EmojiMultipleChoice from '@/assets/icons/submenuIcons/emoji-multipleChoice.svg?react';
import EmojiNumber from '@/assets/icons/submenuIcons/emoji-number.svg?react';
import EmojiParagraph from '@/assets/icons/submenuIcons/emoji-paragraph.svg?react';
import EmojiShortText from '@/assets/icons/submenuIcons/emoji-shortText.svg?react';
import EmojiTelephone from '@/assets/icons/submenuIcons/emoji-telephone.svg?react';
import EmojiSelection from '@/assets/icons/submenuIcons/emojji-selection.svg?react';
//formIcon
import EmojiCopy from '@/assets/icons/formIcons/emoji-copy.svg?react';
import EmojiPen from '@/assets/icons/formIcons/emoji-pen.svg?react';
import EmojiTrash from '@/assets/icons/formIcons/emoji-trash.svg?react';

export const FindEmojiElement = (emojiName: string): React.JSX.Element => {
  let property: keyof typeof Emoji; // Type is 'foo' | 'bar'
  for (property in Emoji) {
    let value = Emoji[property];
    if (value.name == emojiName) {
      return value.element;
    }
  }
};

export const Emoji: IEmojiList = {
  Check: {
    element: <SvgCheck />,
    name: 'check',
  },
  Warning: {
    element: <SvgWarning />,
    name: 'warning',
  },
  NegativeCross: {
    element: <SvgNegativeCross />,
    name: 'negative-cross',
  },
  Heart: {
    element: <SvgHeart />,
    name: 'heart',
  },
  Trophy: {
    element: <SvgTrophy />,
    name: 'trophy',
  },
  Fire: {
    element: <SvgFire />,
    name: 'fire',
  },
  SmileEyes: {
    element: <SvgSmileEyes />,
    name: 'smile-eyes',
  },
  Sunglasses: {
    element: <SvgSunglasses />,
    name: 'sunglasses',
  },
  Thinking: {
    element: <SvgThinking />,
    name: 'thinking',
  },
  Crying: {
    element: <SvgCrying />,
    name: 'crying',
  },
  Triumph: {
    element: <SvgTriumph />,
    name: 'triumph',
  },
  Pouting: {
    element: <SvgPouting />,
    name: 'pouting',
  },
  BarChart: {
    element: <SvgBarChart />,
    name: 'bar-chart',
  },
  Rocket: {
    element: <SvgRocket />,
    name: 'rocket',
  },
  MoneyBag: {
    element: <SvgMoneyBag />,
    name: 'money-bag',
  },
  ThumbsUp: {
    element: <SvgThumbsUp />,
    name: 'thumbs-up',
  },
  Clapping: {
    element: <SvgClapping />,
    name: 'clapping',
  },
  Bell: {
    element: <SvgBell />,
    name: 'bell',
  },
  Basketball: {
    element: <SvgBasketball />,
    name: 'basketball',
  },
  Medal: {
    element: <SvgMedal />,
    name: 'medal',
  },
  Tools: {
    element: <SvgTools />,
    name: 'tools',
  },
  RocketEa: {
    element: <SvgRocketEa />,
    name: 'rocket-ea',
  },
  //new icons
  Mais: {
    element: <SvgMais />,
    name: 'mais',
  },
  Alien: {
    element: <EmojiAlien />,
    name: 'alien',
  },
  BallotBox: {
    element: <EmojiBallotBox />,
    name: 'ballot-box',
  },
  Bomb: {
    element: <EmojiBomb />,
    name: 'bomb',
  },
  ChequeredFlag: {
    element: <EmojiChequeredFlag />,
    name: 'chequered-flag',
  },
  Crossmark: {
    element: <EmojiCrossmark />,
    name: 'crossmark',
  },
  DirectHit: {
    element: <EmojiDirectHit />,
    name: 'direct-hit',
  },
  Envelope: {
    element: <EmojiEnvelope />,
    name: 'envelope',
  },
  ExclamationMark: {
    element: <EmojiExclamationMark />,
    name: 'exclamation-mark',
  },
  ExplodingHead: {
    element: <EmojiExplodingHead />,
    name: 'exploding-head',
  },
  Eyes: {
    element: <EmojiEyes />,
    name: 'eyes',
  },
  FacingUp: {
    element: <EmojiFacingUp />,
    name: 'facing-up',
  },
  FlexedBiceps: {
    element: <EmojiFlexedBiceps />,
    name: 'flexed-biceps',
  },
  Gear: {
    element: <EmojiGear />,
    name: 'gear',
  },
  Globe: {
    element: <EmojiGlobe />,
    name: 'globe',
  },
  GlowingStar: {
    element: <EmojiGlowingStar />,
    name: 'glowing-star',
  },
  GreenCircle: {
    element: <EmojiGreenCircle />,
    name: 'green-circle',
  },
  Hammer: {
    element: <EmojiHammer />,
    name: 'hammer',
  },
  HeavyCheckMark: {
    element: <EmojiHeavyCheckMark />,
    name: 'heavy-check-mark',
  },
  HeavyDollarSign: {
    element: <EmojiHeavyDollarSign />,
    name: 'heavy-dollar-sign',
  },
  HeavyPlusSign: {
    element: <EmojiHeavyPlusSign />,
    name: 'heavy-plus-sign',
  },
  HighBrowns: {
    element: <EmojiHignBrowns />,
    name: 'high-browns',
  },
  HighVoltage: {
    element: <EmojiHighVoltage />,
    name: 'high-voltage',
  },
  Hourglass: {
    element: <EmojiHourglass />,
    name: 'hourglass',
  },
  Key: {
    element: <EmojiKey />,
    name: 'key',
  },
  Light: {
    element: <EmojiLight />,
    name: 'light',
  },
  Memo: {
    element: <EmojiMemo />,
    name: 'memo',
  },
  NeutralFace: {
    element: <EmojiNeutralFace />,
    name: 'neutral-face',
  },
  NoEntry: {
    element: <EmojiNoEntry />,
    name: 'no-entry',
  },
  OpenBook: {
    element: <EmojiOpenBook />,
    name: 'open-book',
  },
  Package: {
    element: <EmojiPackage />,
    name: 'package',
  },
  Pencil: {
    element: <EmojiPencil />,
    name: 'pencil',
  },
  PoutingFace: {
    element: <EmojiPoutingFace />,
    name: 'pouting-face',
  },
  PushPin: {
    element: <EmojiPushPin />,
    name: 'push-pin',
  },
  RedCircle: {
    element: <EmojiRedCircle />,
    name: 'red-circle',
  },
  RoundPushpin: {
    element: <EmojiRoundPushpin />,
    name: 'round-pushpin',
  },
  Shield: {
    element: <EmojiShield />,
    name: 'shield',
  },
  ShushingFace: {
    element: <EmojiShushingFace />,
    name: 'shushing-face',
  },
  Skull: {
    element: <EmojiSkull />,
    name: 'skull',
  },
  SlightlyEsmilingFace: {
    element: <EmojiSlightlyEsmilingFace />,
    name: 'slightly-esmiling-face',
  },
  Smile: {
    element: <EmojiSmile />,
    name: 'smile',
  },
  SportsMedal: {
    element: <EmojiSportsMedal />,
    name: 'sports-medal',
  },
  StarStruck: {
    element: <EmojiStarStruck />,
    name: 'star-struck',
  },
  StopSign: {
    element: <EmojiStopSign />,
    name: 'stop-sign',
  },
  StopWatch: {
    element: <EmojiStopWatch />,
    name: 'stop-watch',
  },
  TelephoneReceiver: {
    element: <EmojiTelephoneReceiver />,
    name: 'telephone-receiver',
  },
  Warning2: {
    element: <EmojiWarning />,
    name: 'warning',
  },
  Trophy2: {
    element: <EmojiTrophy />,
    name: 'trophy',
  },
  Wastebasket: {
    element: <EmojiWastebasket />,
    name: 'wastebasket',
  },
  WhiteCheck: {
    element: <EmojiWhiteCheck />,
    name: 'white-check',
  },
  WhiteMediumStar: {
    element: <EmojiWhiteMediumStar />,
    name: 'medium-star',
  },
  WritingHand: {
    element: <EmojiWritingHand />,
    name: 'writing-hand',
  },
  MenuFaces: {
    element: <EmojiMenuFaces />,
    name: 'menu-faces',
  },
  MenuObjects: {
    element: <EmojiMenuObjects />,
    name: 'menu-objects',
  },
  MenuRecents: {
    element: <EmojiMenuRecents />,
    name: 'menu-recents',
  },
  MenuSigns: {
    element: <EmojiMenuSigns />,
    name: 'menu-signs',
  },
  //submenu
  SubmenuAttach: {
    element: <EmojiAttach />,
    name: 'submenu-attach',
  },
  SubmenuCnpj: {
    element: <EmojiCnpj />,
    name: 'submenu-cnpj',
  },
  SubmenuCoin: {
    element: <EmojiCoin />,
    name: 'submenu-coin',
  },
  SubmenuCpf: {
    element: <EmojiCpf />,
    name: 'submenu-cpf',
  },
  SubmenuDate: {
    element: <EmojiDate />,
    name: 'submenu-date',
  },
  SubmenuEmail: {
    element: <EmojiEmail />,
    name: 'submenu-email',
  },
  SubmenuHeader: {
    element: <EmojiHeader />,
    name: 'submenu-header',
  },
  SubmenuLink: {
    element: <EmojiLink />,
    name: 'submenu-link',
  },
  SubmenuLongText: {
    element: <EmojiLongText />,
    name: 'submenu-longtext',
  },
  SubmenuMultipleChoice: {
    element: <EmojiMultipleChoice />,
    name: 'submenu-multiplechoice',
  },
  SubmenuNumber: {
    element: <EmojiNumber />,
    name: 'submenu-number',
  },
  SubmenuParagraph: {
    element: <EmojiParagraph />,
    name: 'submenu-paragraph',
  },
  SubmenuSelection: {
    element: <EmojiSelection />,
    name: 'submenu-selection',
  },
  SubmenuShortText: {
    element: <EmojiShortText />,
    name: 'submenu-shortext',
  },
  SubmenuTelephone: {
    element: <EmojiTelephone />,
    name: 'submenu-telephone',
  },
  FormCopy: {
    element: <EmojiCopy />,
    name: 'form-copy',
  },
  FormPen: {
    element: <EmojiPen />,
    name: 'form-pen',
  },
  FormTrash: {
    element: <EmojiTrash />,
    name: 'form-trash',
  },
};
