export enum WorkflowInputTypeEnum {
  Text = 'text',
  LongText = 'long-text',
  Date = 'date',
  DateTime = 'date-time',
  Time = 'time',
  Number = 'number',
  Checkbox = 'checkbox',
  Select = 'select',
  SelectMultiple = 'select-multiple',
  Email = 'email',
  Link = 'link',
  PhoneNumber = 'phone-number',
  File = 'file',
  Cpf = 'cpf',
  Cnpj = 'cnpj',
  CpfCnpj = 'cpf-cnpj',
  Currency = 'currency',
  Header = 'header',
  Paragraph = 'paragraph',
}