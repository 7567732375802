import React from 'react';
import {
  Box,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import {FormContainer} from '@/components/index';

interface MultipleChoiceProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const example = ['Oliver Hansen'];

const MultipleChoice: React.FC<MultipleChoiceProps> = ({
  title = 'Múltipla escolha',
  onEdit,
  onCopy,
  onDelete,
}) => {
  const [item, setItem] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof item>) => {
    const {
      target: {value},
    } = event;
    setItem(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Select
            size="small"
            hiddenLabel
            multiple
            value={item}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.join(', ')}
            sx={{borderColor: '#B2BAC2'}}
          >
            <MenuItem disabled value="">
              <em>selecione uma ou mais opções</em>
            </MenuItem>
            {example.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={item.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormContainer>
    </Box>
  );
};

export default MultipleChoice;
