import React from 'react';
import {
  Modal,
  Fade,
  IconButton,
  Box,
  Button,
  Checkbox,
  TextField,
  InputLabel,
  Typography,
} from '@mui/material';
import {useWindowWidth} from '@/utils/useWindowWidth';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {Formik} from 'formik';
import SelectWorkflowIcon from '@/components/selectWorkflowIcon';
import * as Yup from 'yup';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import {WorkflowStepCreateUpdate} from '@/services/workflow-step/models/workflow-step-create-update.dto.tsx';
import {ToastSuccess, ToastError, EmojiIcon} from '@/components/shared';
import KanbanShowcase from '@/components/kanbanShowcase/index';
interface KanbanModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: (step?: WorkflowStepCreateUpdate) => Promise<void>;
  data?: WorkflowStepDto;
}

const StepScheme = Yup.object().shape({
  title: Yup.string().required(),
  allowCreateCard: Yup.boolean().required(),
  icon: Yup.object().required().shape({
    label: Yup.object(),
    value: Yup.string().required(),
  }),
});

const KanbanModal: React.FC<KanbanModalProps> = ({
  open,
  onClose,
  onSubmit,
  data,
}) => {
  const width = useWindowWidth();

  const handleSubmit = async () => {
    try {
      await onSubmit?.();
      ToastSuccess({
        title: 'Sucesso!',
        text: `Etapa ${data?.id ? 'editada' : 'cadastrada'} com sucesso!`,
      });
    } catch (error) {
      ToastError({
        title: 'Erro!',
        text: `Erro ao ${data?.id ? 'editar' : 'cadastrar'} uma etapa. Contate o administrador`,
      });
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            padding: '35px',
            position: 'relative',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              title: data?.title || '',
              allowCreateCard: data?.allowCreateCard ?? true,
              icon: data?.icon
                ? WorkflowIconList.find((i) => i.value === data.icon)
                : WorkflowIconList[0],
            }}
            validationSchema={StepScheme}
            onSubmit={async (values) => {
              let payload: WorkflowStepCreateUpdate = {
                title: values.title,
                allowCreateCard: values.allowCreateCard,
                icon: values.icon.value,
              };

              try {
                await onSubmit?.(payload);
              } catch (error) {
                ToastError({
                  title: 'Erro!',
                  text: `Erro ao ${
                    data?.id ? 'editar' : 'cadastrar'
                  } uma etapa. Contate o administrador`,
                });
                console.error(error);
              }
            }}
          >
            {({setFieldValue, values, setFieldTouched}) => (
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                  }}
                  justifyContent="end"
                >
                  <IconButton onClick={handleSubmit}>
                    <CloseOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Box>
                <Typography variant="subtitle1">
                  Escolha um ícone e um nome para esta etapa
                </Typography>
                <Box
                  display="flex"
                  flexDirection={width > 768 ? 'row' : 'column'}
                  alignItems={width > 768 ? 'center' : undefined}
                  gap="16px"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="16px"
                  >
                    <Typography variant="body2">Ícone</Typography>
                    <SelectWorkflowIcon
                      id="workflow-select-icon"
                      placeholder={''}
                      className="workflow-select-icon"
                      value={values.icon}
                      onChange={async (v) => {
                        await setFieldValue('icon', v);
                        await setFieldTouched('icon');
                      }}
                      loading={false}
                    />
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Checkbox></Checkbox>
                    <Typography variant="body2">
                      Criar cards nessa etapa
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <InputLabel htmlFor="filled-required" required>
                    Título
                  </InputLabel>
                  <TextField
                    required
                    id="filled-required"
                    value={values.title}
                    onChange={(e) => setFieldValue('title', e.target.value)}
                    placeholder="Digite um título"
                    variant="standard"
                    sx={{
                      maxWidth: '266px',
                    }}
                  />
                </Box>
                <KanbanShowcase
                  emoji={values.icon.value}
                  title={values.title}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '15px',
                    right: '15px',
                  }}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    Concluir
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
};

export default KanbanModal;
