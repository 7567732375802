import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface LongTextProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const LongText: React.FC<LongTextProps> = ({
  title = 'Texto Longo',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input multiline rows={3} />
      </FormContainer>
    </Box>
  );
};

export default LongText;
