import {faIdCardClip} from '@fortawesome/pro-solid-svg-icons';

const Input = () => {
  return <></>;
};

const CardIcon = faIdCardClip;

export default {
  CardIcon,
  Input,
};
