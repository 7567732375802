import React, {useRef} from 'react';
import {useDrop} from 'react-dnd';
import {Grid} from '@mui/material';
import WorkflowKanbanHeader from '@/pages/workflow/kanban/components/workflowKanbanHeader';

const KanbanColumn = ({step, cards, changeCardColumn, children}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    drop(item_id) {
      changeCardColumn(item_id, step.id);
    },
  });
  drop(ref);

  return (
    <div ref={ref} className={'workflow-board-column'}>
      <Grid item xs={12} className={'workflow-board'}>
        <WorkflowKanbanHeader
          icon={step.icon}
          text={step.title}
          counter={cards.filter((c) => c.step_id === step.id).length}
        />
        {children}
      </Grid>
    </div>
  );
};

export default KanbanColumn;
