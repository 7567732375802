import http from '../../axios';
import {WorkflowDto} from '@/models/workflow.dto.ts';
import {AxiosResponse} from 'axios';

const getAllWorkflows = (): Promise<AxiosResponse<WorkflowDto[]>> => {
  let url = `workflow/list`;

  return http.get<WorkflowDto[]>(url);
};

const getWorkflowById = (id: string): Promise<AxiosResponse<WorkflowDto>> => {
  let url = `workflow/${id}`;

  return http.get<WorkflowDto>(url);
};

export {
  getAllWorkflows,
  getWorkflowById,
};