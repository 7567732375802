import {
  Box,
  IconButton,
  Stack,
  Typography,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';
import {Formik} from 'formik';
import * as Yup from 'yup';
import './index.css';
import {ToastSuccess, ToastError} from '@/components/shared';
import SelectWorkflowIcon from '@/components/selectWorkflowIcon';
import React from 'react';
import {SectionHeader} from '@/components/index';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {useNavigate} from 'react-router';
import {WorkflowIconList} from '@/components/selectWorkflowIcon/workflowIconList';
import {WorkflowStepCreateUpdate} from '@/services/workflow-step/models/workflow-step-create-update.dto.tsx';
import {WorkflowStepDto} from '@/models/workflow-step.dto.ts';
import {CardShowcase} from '@/components/index';
interface NewCardProps {
  onSubmit?: (step?: WorkflowStepCreateUpdate) => Promise<void>;
  data?: WorkflowStepDto;
}

const StepScheme = Yup.object().shape({
  title: Yup.string().required(),
  allowCreateCard: Yup.boolean().required(),
  icon: Yup.object().required().shape({
    label: Yup.object(),
    value: Yup.string().required(),
  }),
});

const NewCard: React.FC<NewCardProps> = ({onSubmit, data}) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await onSubmit?.();
      ToastSuccess({
        title: 'Sucesso!',
        text: `Etapa ${data?.id ? 'editada' : 'cadastrada'} com sucesso!`,
      });
    } catch (error) {
      ToastError({
        title: 'Erro!',
        text: `Erro ao ${data?.id ? 'editar' : 'cadastrar'} uma etapa. Contate o administrador`,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          title: data?.title || '',
          allowCreateCard: data?.allowCreateCard ?? true,
          icon: data?.icon
            ? WorkflowIconList.find((i) => i.value === data.icon)
            : WorkflowIconList[0],
        }}
        validationSchema={StepScheme}
        onSubmit={async (values) => {
          let payload: WorkflowStepCreateUpdate = {
            title: values.title,
            allowCreateCard: values.allowCreateCard,
            icon: values.icon.value,
          };

          try {
            await onSubmit?.(payload);
          } catch (error) {
            ToastError({
              title: 'Erro!',
              text: `Erro ao ${
                data?.id ? 'editar' : 'cadastrar'
              } uma etapa. Contate o administrador`,
            });
            console.error(error);
          }
        }}
      >
        {({setFieldValue, values, setFieldTouched}) => (
          <Box marginRight="40px" width="100%">
            <Stack mb="28px" gap="30px">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <SectionHeader
                  title="Pronto para começar?"
                  subtitle="Selecione um ícone e um título para este fluxo"
                />

                <IconButton onClick={() => navigate(`/workflow`)}>
                  <CloseOutlinedIcon fontSize="medium" />
                </IconButton>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="65px"
              >
                <CardShowcase title={values.title} />
                <Box display="flex" gap="20px" flexDirection="column">
                  <Stack direction="row" alignItems="center" gap="10px">
                    <Typography variant="body2">Ícone</Typography>
                    <SelectWorkflowIcon
                      id="workflow-select-icon"
                      placeholder={''}
                      className="workflow-select-icon"
                      value={values.icon}
                      onChange={async (v) => {
                        await setFieldValue('icon', v);
                        await setFieldTouched('icon');
                      }}
                      loading={false}
                    />
                  </Stack>
                  <Box>
                    <InputLabel
                      htmlFor="filled-required"
                      className="input-label"
                      required
                    >
                      Título
                    </InputLabel>
                    <TextField
                      required
                      id="filled-required"
                      placeholder="Digite um título"
                      variant="standard"
                      sx={{
                        maxWidth: '266px',
                        marginBottom: '40px',
                      }}
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Stack>
            <Box
              component="div"
              width="100%"
              height="1px"
              bgcolor="#00274E"
              marginTop="200px"
            />

            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              paddingY="10px"
            >
              <Button>Voltar</Button>
              <Button variant="contained" color="primary">
                Concluir
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default NewCard;
