import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';
import {
  Email,
  Section,
  Cpf,
  LinkUrl,
  ShortText,
  Cnpj,
  Telephone,
  Coin,
  LongText,
  Number,
  CustomizeHeader,
  AttachFile,
  MultipleChoice,
  SelectionBox,
  DraggableComponent,
  Paragraph,
  DatePicker,
} from '@/components/index';

interface FormItem {
  id: string;
  label: string;
  emoji: any;
  component: any;
  colSpan: number;
}

interface SubmenuProps {
  onSelect?: (formItem: FormItem) => void;
}

const Submenu: React.FC<SubmenuProps> = ({onSelect}) => {
  const formItems: FormItem[] = [
    {
      id: '1',
      label: 'Anexar arquivo',
      emoji: Emoji.SubmenuAttach,
      component: AttachFile,
      colSpan: 6,
    },
    {
      id: '2',
      label: 'Cabeçalho',
      emoji: Emoji.SubmenuHeader,
      component: CustomizeHeader,
      colSpan: 12,
    },
    {
      id: '3',
      label: 'Caixa de seleção',
      emoji: Emoji.SubmenuSelection,
      component: SelectionBox,
      colSpan: 6,
    },
    {
      id: '4',
      label: 'CPF',
      emoji: Emoji.SubmenuCpf,
      component: Cpf,
      colSpan: 6,
    },
    {
      id: ' 5',
      label: 'CNPJ',
      emoji: Emoji.SubmenuCnpj,
      component: Cnpj,
      colSpan: 6,
    },
    {
      id: '6',
      label: 'Data',
      emoji: Emoji.SubmenuDate,
      component: DatePicker,
      colSpan: 6,
    },
    {
      id: '7',
      label: 'E-mail',
      emoji: Emoji.SubmenuEmail,
      component: Email,
      colSpan: 6,
    },
    {
      id: '8',
      label: 'Link URL',
      emoji: Emoji.SubmenuLink,
      component: LinkUrl,
      colSpan: 6,
    },
    {
      id: '9',
      label: 'Moeda',
      emoji: Emoji.SubmenuCoin,
      component: Coin,
      colSpan: 6,
    },
    {
      id: '10',
      label: 'Múltipla escolha',
      emoji: Emoji.SubmenuMultipleChoice,
      component: MultipleChoice,
      colSpan: 6,
    },
    {
      id: '11',
      label: 'Número',
      emoji: Emoji.SubmenuNumber,
      component: Number,
      colSpan: 6,
    },
    {
      id: '12',
      label: 'Parágrafo',
      emoji: Emoji.SubmenuParagraph,
      component: Paragraph,
      colSpan: 12,
    },
    {
      id: '13',
      label: 'Telefone',
      emoji: Emoji.SubmenuTelephone,
      component: Telephone,
      colSpan: 6,
    },
    {
      id: '14',
      label: 'Texto curto',
      emoji: Emoji.SubmenuShortText,
      component: ShortText,
      colSpan: 6,
    },
    {
      id: '15',
      label: 'Texto longo',
      emoji: Emoji.SubmenuLongText,
      component: LongText,
      colSpan: 12,
    },
    {
      id: '16',
      label: 'Adicionar seção',
      emoji: Emoji.SubmenuLongText,
      component: Section,
      colSpan: 12,
    },
  ];

  const handleSelect = (item: FormItem) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <Box
      height="100vh"
      padding="18px 50px 40px 45px"
      marginLeft="15px"
      className="submenu-board"
      sx={{backgroundColor: '#FFFFFF'}}
    >
      <Box marginBottom="15px">
        <Typography variant="body2" fontWeight="600" marginBottom="15px">
          Compartilhar link
        </Typography>
        <Divider />
      </Box>
      <Box
        display="flex"
        height="100vh"
        flexDirection="column"
        justifyContent="space-between"
      >
        {formItems.map((item) => (
          <DraggableComponent
            key={item.id}
            id={item.id}
            label={item.label}
            emoji={item.emoji}
            component={item.component}
            colSpan={item.colSpan}
            onSelect={() => handleSelect(item)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Submenu;
