import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, TextField, InputAdornment} from '@mui/material';
import {
  SectionHeader,
  Checklist,
  FirstAccessFooter,
  Select,
  MembersTable,
} from '@/components/index';
import SearchTable from '@/assets/icons/tableIcon/SearchTable.svg?react';

const items = [
  {label: 'Ativo', icon: 'faCheckCircle', color: 'rgba(0, 176, 80, 1)'},
  {label: 'Pendente', icon: 'faClock', color: 'rgba(252, 151, 0, 1)'},
  {label: 'Inativo', icon: 'faTimesCircle', color: 'rgba(255, 56, 87, 1)'},
];

const MembersScreen: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <SectionHeader
              title="Convidar membros"
              subtitle="Adicione ou importe todos os membros de cada equipe."
            />
            <Box
              component="section"
              display="flex"
              flexDirection="row"
              alignItems="end"
              gap="30px"
              marginBottom="30px"
            >
              <Button
                variant="contained"
                onClick={() => navigate('/adicionar-membro')}
              >
                Adicionar
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/importar-membros')}
                sx={{
                  background: 'rgba(8, 151, 203, 1)',
                  ':hover': {
                    background: 'rgba(6, 120, 160, 1)',
                  },
                }}
              >
                Importar
              </Button>
              <Select title="Situação" items={items} />
              <TextField
                placeholder="Pesquisar funcionário"
                variant="outlined"
                sx={{
                  minWidth: '300px',
                  '& .MuiInputBase-input': {
                    color: 'rgba(0, 39, 78, 1)',
                    fontSize: '16px',
                    padding: '14px',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(0, 39, 78, 0.5)',
                    opacity: 1,
                  },
                }}
                size="medium"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchTable />{' '}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <MembersTable />
          </div>

          <FirstAccessFooter
            leftButtonRoute="/minhas-equipes"
            rightButtonRoute="/meus-fluxos"
            leftButtonText="Voltar"
            rightButtonText="Avançar"
          />
        </Box>
        <Checklist active="/meus-membros" />
      </Box>
    </>
  );
};

export default MembersScreen;
