import React from 'react';
import {Box, Checkbox, Typography} from '@mui/material';
import {FormContainer} from '@/components/index';

interface SelectionBoxProps {
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({
  title = 'Caixa de seleção',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <FormContainer
      title={title}
      onEdit={onEdit}
      onCopy={onCopy}
      onDelete={onDelete}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox />
        <Typography variant="body2">{title}</Typography>
      </Box>
    </FormContainer>
  );
};

export default SelectionBox;
