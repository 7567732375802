import React from 'react';
import {Box} from '@mui/material';
import {Input, FormContainer} from '@/components/index';

interface ParagraphProps {
  title?: string;
  text?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Paragraph: React.FC<ParagraphProps> = ({
  title = 'Parágrafo',
  text = '',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <FormContainer
        title={text}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <Input value={title} />
      </FormContainer>
    </Box>
  );
};

export default Paragraph;
