import App from '../App.tsx';
import {createBrowserRouter} from 'react-router-dom';
import NotFound from '../pages/NotFound.tsx';
import WorkflowKanban from '@/pages/workflow/kanban';
import NewCard from '@/pages/workflow/newCard';
import NewForm from '@/pages/workflow/newForm';
import FirstAccessScreen from '@/pages/firstAccess/FirstAccessScreen.tsx';
import NewCompanyScreen from '@/pages/companyScreen/CompanyScreen.tsx';
import TeamsScreen from '@/pages/teamsScreen/TeamsScreen.tsx';
import MembersScreen from '@/pages/membersScreen/MembersScreen.tsx';
import NewMembersScreen from '@/pages/newMemberScreen/NewMemberScreen.tsx';
import ImportMembersScreen from '@/pages/importMembersScreen/ImportMembersScreen.tsx';
import WorkflowScreen from '@/pages/workflows/Workflow.tsx';
import NewWorkflowScreen from '@/pages/newWorflow/NewWorkflow.tsx';
import NewTeamScreen from '@/pages/newTeam/NewTeam.tsx';

export default createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {path: '/meus-fluxos', element: <WorkflowScreen />},

      {
        path: '/workflow/:id/kanban',
        element: <WorkflowKanban />,
      },
      {
        path: '/workflow/new-card',
        element: <NewCard />,
      },
      {
        path: '/workflow/new-form',
        element: <NewForm />,
      },
      {
        path: '/primeiro-acesso',
        element: <FirstAccessScreen />,
      },
      {
        path: '/minhas-empresas',
        element: <NewCompanyScreen />,
      },
      {
        path: '/minhas-equipes',
        element: <TeamsScreen />,
      },
      {
        path: '/meus-membros',
        element: <MembersScreen />,
      },
      {
        path: '/adicionar-membro',
        element: <NewMembersScreen />,
      },
      {
        path: '/importar-membros',
        element: <ImportMembersScreen />,
      },
      {
        path: '/adicionar-fluxo',
        element: <NewWorkflowScreen />,
      },
      {
        path: '/adicionar-equipe',
        element: <NewTeamScreen />,
      },
    ],
  },
]);
