import React, {useState, useRef} from 'react';
import {Box, Typography, Button, TextField, InputLabel} from '@mui/material';
import {
  SectionHeader,
  Checklist,
  Modal,
  FirstAccessFooter,
} from '@/components/index';
import {useNavigate} from 'react-router-dom';
import Icon from '@/components/shared/icon';
import {faBuilding} from '@fortawesome/pro-solid-svg-icons';

const NewCompanyScreen: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalIsOpen, modalSetIsOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string | null>(null);
  const [tempImage, setTempImage] = useState<string | null>(null);

  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleCloseModal = () => {
    modalSetIsOpen(false);
    setTempImage(null);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImage(imageUrl);
      modalSetIsOpen(true);
    }
  };

  const handleImageCropped = (croppedImage: string) => {
    setImage(croppedImage);
    handleCloseModal();
  };

  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <SectionHeader
              title="Cadastrar empresa"
              subtitle="Crie e personalize sua empresa do seu jeito. Em seguida, clique em ‘Avançar’ para configurarmos as equipes da sua empresa."
            />
            <Box
              component="section"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="30px"
            >
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={handleIconClick}
                sx={{
                  borderRadius: '100%',
                  backgroundColor: '#98C6E0',
                  width: '150px',
                  height: '150px',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {!image && (
                  <Icon
                    width={80}
                    height={80}
                    faIcon={faBuilding}
                    color="#ffffff"
                  />
                )}
              </Box>
              <input
                type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
                accept="image/*"
              />
              <Box>
                <InputLabel
                  htmlFor="filled-required"
                  sx={{
                    color: '#00152A',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                  required
                >
                  Nome da empresa
                </InputLabel>
                <TextField
                  required
                  id="filled-required"
                  placeholder="Digite o nome da sua empresa"
                  variant="outlined"
                  InputProps={{
                    style: {
                      fontSize: '16px',
                      color: '#4D5156',
                      fontWeight: '500',
                    },
                  }}
                  sx={{
                    minWidth: '300px',
                    marginBottom: '40px',
                  }}
                />
              </Box>
            </Box>
          </div>
          <FirstAccessFooter
            leftButtonRoute="/primeiro-acesso"
            rightButtonRoute="/minhas-equipes"
            leftButtonText="Voltar"
            rightButtonText="Avançar"
          />
        </Box>
        <Checklist active="/minhas-empresas" />
      </Box>
      <Modal
        open={modalIsOpen}
        onClose={handleCloseModal}
        image={tempImage}
        onImageCropped={handleImageCropped}
      />
    </>
  );
};

export default NewCompanyScreen;
