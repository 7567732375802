import React, {useState, useEffect} from 'react';
import {Box, Typography, IconButton, Button, Switch} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Input} from '@/components/index';

interface EditComponentProps {
  open: boolean;
  onClose: () => void;
  onSave: (newTitle: string) => void;
  initialTitle: string;
}

const EditComponent: React.FC<EditComponentProps> = ({
  open,
  onClose,
  onSave,
  initialTitle,
}) => {
  const [title, setTitle] = useState<string>(initialTitle);

  useEffect(() => {
    console.log('Initial Title Changed:', initialTitle);
    setTitle(initialTitle);
  }, [initialTitle]);

  const handleSave = () => {
    console.log('Saving Title:', title);
    onSave(title);
    onClose();
  };

  return (
    <Box
      className="edit-component-form"
      sx={{
        position: 'fixed',
        top: '0',
        right: open ? '0' : '-50%',
        backgroundColor: '#ffffff',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        width: '18%',
        height: '90%',
        padding: '40px 25px 25px 25px',
        transition: 'right 0.3s ease',
      }}
    >
      <Box position="absolute" right={2} top={2}>
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '13px',
            }}
          >
            Nome do campo
          </Typography>
          <Input
            placeholder="Digite"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Switch color="warning" />
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              Resposta obrigatória
            </Typography>
          </Box>
        </Box>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

export default EditComponent;
