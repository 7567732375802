import React from 'react';
import {TextField, Typography, Box, InputAdornment} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/pro-light-svg-icons';

interface TableSelectProps {
  title: string;
  placeholder?: string;
  width?: string;
  link?: boolean;
}

const MembersInput: React.FC<TableSelectProps> = ({
  title,
  placeholder,
  width,
  link,
}) => {
  return (
    <Box>
      <Typography fontWeight="500" fontSize="14px">
        {title}
      </Typography>
      <TextField
        size="small"
        placeholder={placeholder}
        sx={{
          width: width ? width : undefined,
          minWidth: '400px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#00c1eb',
            },
            '&:hover fieldset': {
              borderColor: '#00c1eb',
            },
            '& input::placeholder': {
              color: 'rgba(0, 21, 42, 1)',
              fontSize: '14px',
              fontWeight: '500',
            },
          },
        }}
        InputProps={{
          endAdornment: link ? (
            <InputAdornment position="end">
              <FontAwesomeIcon
                icon={faCopy}
                style={{
                  cursor: 'pointer',
                  height: '18px',
                  color: 'rgba(77, 81, 86, 1)',
                }}
              />
            </InputAdornment>
          ) : null,
        }}
      />
    </Box>
  );
};

export default MembersInput;
