import React from 'react';

import './index.css';

export const AnimatedMenuItem2 = ({text, icon}: {text: string; icon: any}) => {
  const style = {'--text': text} as React.CSSProperties;

  return (
    <div data-text={text} className="tooltip" style={style}>
      <img src={icon} className="tooltip svg" />
    </div>
  );
};
