import {IconButton, Box, Button, Typography} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {WorkflowDto} from '@/models/workflow.dto.ts';
import {useNavigate} from 'react-router';
import NewFormKanban from '@/assets/svg/new-form-kanban.svg';
interface NewCardFormProp {
  isOpen: boolean;
  onClose: () => void;
  workflow?: Partial<WorkflowDto>;
}

const NewCardForm: React.FC<NewCardFormProp> = ({
  isOpen,
  onClose,
  workflow,
}: NewCardFormProp) => {
  const navigate = useNavigate();

  return (
    <Box
      className="kanban-new-card-form"
      sx={{
        position: 'fixed',
        top: '0',
        right: isOpen ? 'calc(0px)' : 'calc(0px - 47%)',
        backgroundColor: '#fffff',
        zIndex: 1000,
        width: '47%',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Box
        component="div"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" justifyContent="start" component="div">
          <Box component="div" marginX="40px" marginTop="44px">
            <Typography fontSize="18px" fontWeight="700">
              Hora de criar!
            </Typography>
            <Typography fontSize="16px" fontWeight="500">
              Vamos começar pelo formulário! Ele é o ponto de partida para você
              definir quais informações apareceram no seu fluxo{' '}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon fontSize="medium" />
          </IconButton>
        </Box>

        <img src={NewFormKanban} width="400px" />
        <Box>
          <Button
            onClick={() => navigate(`/workflow/new-form`)}
            sx={{
              padding: '5px 15px',
              backgroundColor: '#0897CB',
              color: '#FFFFFF',
              fontWeight: '500',
              borderRadius: '5px',
              marginBottom: '40px',
              '&:hover': {
                backgroundColor: '#B7E6FF',
                color: '#00152A',
              },
            }}
          >
            Criar meu formulário
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewCardForm;
