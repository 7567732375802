import React from 'react';
import {Box} from '@mui/material';
import {
  SectionHeader,
  Checklist,
  MembersInput,
  FirstAccessFooter,
  MembersTable,
  ConfirmationModal,
} from '@/components/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-solid-svg-icons';
import {useNavigate} from 'react-router-dom';

const items = [
  {
    id: 1,
    name: 'John Doe',
    email: 'john@example.com',
    team: 'Suporte',
    profile: 'Administrador',
  },
  {
    id: 2,
    name: 'Jane Smith',
    email: 'jane@example.com',
    team: 'Marketing',
    profile: 'Membro',
  },
  {
    id: 3,
    name: 'Jane Smith',
    email: 'jane@example.com',
    team: 'Contábil',
    profile: 'Visualização',
  },
  {
    id: 4,
    name: 'Jane Smith',
    email: 'jane@example.com',
    team: 'Suporte',
    profile: 'Administrador',
  },
];

const ImportMembersScreen: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(true);

  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionHeader
                title="Importe toda sua equipe!"
                subtitle="Selecione os membros desejados, atribua as equipes e defina um perfil para cada um."
              />
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => navigate('/meus-membros')}
                style={{
                  cursor: 'pointer',
                  height: '24px',
                  color: 'rgba(0, 39, 78, 1)',
                }}
              />
            </Box>
            <Box marginBottom="24px">
              <MembersTable itens={items} />
            </Box>
            <MembersInput
              title="Se preferir encaminhe o link:"
              width="500px"
              link
              placeholder="https://www.empreendeaqui.com.br/?gad_source=1&gclid=CjwKCAjwxNW2BhAkEiw"
            />
          </div>
          <FirstAccessFooter
            leftButtonRoute="/meus-membros"
            rightButtonRoute="/meus-membros"
            leftButtonText="Voltar"
            rightButtonText="Avançar"
          />
        </Box>
        <Checklist active="/meus-membros" />
      </Box>
      <ConfirmationModal
        modalTitle="Importar meu time"
        bodySubtitle="Você esta prestes a convidar 4 membros para as equipes selecionadas. Você deseja continuar?"
        bodyTitle="Atenção!"
        onClose={() => setOpen(false)}
        open={open}
        rightButton="Continuar"
      />
    </>
  );
};

export default ImportMembersScreen;
