import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';

interface IconOption {
  label: JSX.Element;
  value: string;
  category: string;
}

export const WorkflowIconList: IconOption[] = [
  {
    label: <EmojiIcon emoji={Emoji.SlightlyEsmilingFace} />,
    value: Emoji.SlightlyEsmilingFace.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.Smile} />,
    value: Emoji.Smile.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.HighBrowns} />,
    value: Emoji.HighBrowns.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.ShushingFace} />,
    value: Emoji.ShushingFace.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.ExplodingHead} />,
    value: Emoji.ExplodingHead.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.PoutingFace} />,
    value: Emoji.PoutingFace.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.NeutralFace} />,
    value: Emoji.NeutralFace.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.StarStruck} />,
    value: Emoji.StarStruck.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.FlexedBiceps} />,
    value: Emoji.FlexedBiceps.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.WritingHand} />,
    value: Emoji.WritingHand.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.Eyes} />,
    value: Emoji.Eyes.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.Skull} />,
    value: Emoji.Skull.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.Alien} />,
    value: Emoji.Alien.name,
    category: 'faces',
  },
  {
    label: <EmojiIcon emoji={Emoji.Memo} />,
    value: Emoji.Memo.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.RocketEa} />,
    value: Emoji.RocketEa.name,
    category: 'recent',
  },
  {
    label: <EmojiIcon emoji={Emoji.Rocket} />,
    value: Emoji.Rocket.name,
    category: 'objects',
  },

  {
    label: <EmojiIcon emoji={Emoji.Bomb} />,
    value: Emoji.Bomb.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.ChequeredFlag} />,
    value: Emoji.ChequeredFlag.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.DirectHit} />,
    value: Emoji.DirectHit.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Envelope} />,
    value: Emoji.Envelope.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.FacingUp} />,
    value: Emoji.FacingUp.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Gear} />,
    value: Emoji.Gear.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Globe} />,
    value: Emoji.Globe.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.GlowingStar} />,
    value: Emoji.GlowingStar.name,
    category: 'objects',
  },

  {
    label: <EmojiIcon emoji={Emoji.Hammer} />,
    value: Emoji.Hammer.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Hourglass} />,
    value: Emoji.Hourglass.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Key} />,
    value: Emoji.Key.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Light} />,
    value: Emoji.Light.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.OpenBook} />,
    value: Emoji.OpenBook.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Package} />,
    value: Emoji.Package.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Pencil} />,
    value: Emoji.Pencil.name,
    category: 'objects',
  },

  {
    label: <EmojiIcon emoji={Emoji.Shield} />,
    value: Emoji.Shield.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.SportsMedal} />,
    value: Emoji.SportsMedal.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.TelephoneReceiver} />,
    value: Emoji.TelephoneReceiver.name,
    category: 'objects',
  },

  {
    label: <EmojiIcon emoji={Emoji.Trophy2} />,
    value: Emoji.Trophy2.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.Wastebasket} />,
    value: Emoji.Wastebasket.name,
    category: 'objects',
  },
  {
    label: <EmojiIcon emoji={Emoji.StopWatch} />,
    value: Emoji.StopWatch.name,
    category: 'objects',
  },

  {
    label: <EmojiIcon emoji={Emoji.Warning2} />,
    value: Emoji.Warning2.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.WhiteCheck} />,
    value: Emoji.WhiteCheck.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.HeavyDollarSign} />,
    value: Emoji.HeavyDollarSign.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.HeavyPlusSign} />,
    value: Emoji.HeavyPlusSign.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.GreenCircle} />,
    value: Emoji.GreenCircle.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.HeavyCheckMark} />,
    value: Emoji.HeavyCheckMark.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.HighVoltage} />,
    value: Emoji.HighVoltage.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.PushPin} />,
    value: Emoji.PushPin.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.RedCircle} />,
    value: Emoji.RedCircle.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.RoundPushpin} />,
    value: Emoji.RoundPushpin.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.NoEntry} />,
    value: Emoji.NoEntry.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.StopSign} />,
    value: Emoji.StopSign.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.BallotBox} />,
    value: Emoji.BallotBox.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.Crossmark} />,
    value: Emoji.Crossmark.name,
    category: 'signs',
  },
  {
    label: <EmojiIcon emoji={Emoji.ExclamationMark} />,
    value: Emoji.ExclamationMark.name,
    category: 'signs',
  },
];
