import React from 'react';
import {Box, Typography} from '@mui/material';
import {FormContainer} from '@/components/index';

interface SectionProps {
  text?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const Section: React.FC<SectionProps> = ({
  text = '',
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <FormContainer
      title={text}
      onEdit={onEdit}
      onCopy={onCopy}
      onDelete={onDelete}
    >
      <Box display="flex" flexDirection="row">
        <Box height="5px" width="100%" color="#00152A" />
        <Typography variant="body2">Seção</Typography>
      </Box>
    </FormContainer>
  );
};

export default Section;
