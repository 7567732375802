import React from 'react';
import {Box, Typography} from '@mui/material';
import emptyFormImage from '@/assets/svg/new-form.png';

const EmptyForm: React.FC = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="60px"
      >
        <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.5)">
          Arraste aqui os campos que deseja adicionar
        </Typography>
        <img
          src={emptyFormImage}
          style={{display: 'block', width: '100%'}}
          alt="Empty Form"
        />
      </Box>
    </>
  );
};

export default EmptyForm;
