import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider} from 'react-router-dom';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import KeycloakAuth from './auth/KeycloakAuth';
import keycloak from './auth/keycloak.ts';
import router from './routes';

const dev = import.meta.env.DEV;
const company = import.meta.env.VITE_COMPANY_ID;
const token = import.meta.env.VITE_TOKEN;

if (dev) {
  localStorage.setItem('eaqui-token', token);
  localStorage.setItem('eaqui-company', company);
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  dev ? (
    <RouterProvider router={router} />
  ) : (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={({token}) => {
        localStorage.setItem('eaqui-token', token);
      }}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <KeycloakAuth>
        <RouterProvider router={router} />
      </KeycloakAuth>
    </ReactKeycloakProvider>
  ),
);
