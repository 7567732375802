import React, {ReactNode} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';

interface FormContainerProps {
  children?: ReactNode;
  title?: string;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
}

const FormContainer: React.FC<FormContainerProps> = ({
  children,
  title,
  onEdit,
  onCopy,
  onDelete,
}) => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          {title}
        </Typography>
        <Box display="flex" flexDirection="row">
          <IconButton size="small" onClick={onEdit}>
            <EmojiIcon emoji={Emoji.FormPen} width="14px" />
          </IconButton>
          <IconButton size="small" onClick={onCopy}>
            <EmojiIcon emoji={Emoji.FormCopy} width="14px" />
          </IconButton>
          <IconButton size="small" onClick={onDelete}>
            <EmojiIcon emoji={Emoji.FormTrash} width="14px" />
          </IconButton>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default FormContainer;
