import React, {useState, useEffect} from 'react';
import {Box, Button} from '@mui/material';
import {
  SectionHeader,
  CardDraggableComponent,
  WorkflowChoice,
} from '@/components/index';
import WorkflowCard from '@/pages/workflow/components/workflowCard';
import {useNavigate} from 'react-router-dom';
import {ToastError} from '@/components/shared/toast/error';
import {getAllWorkflows} from '@/services/workflow/workflow.service.ts';
import {WorkflowDto} from '@/models/workflow.dto.ts';

const WorkflowScreen: React.FC = () => {
  const [newWorkflow, setNewWorkflow] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState<Array<WorkflowDto>>([]);
  const CARD_TYPE_WORKFLOW = 'CARD_TYPE_WORKFLOW';
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkflowsSync();
  }, []);

  async function fetchWorkflowsSync() {
    setLoading(true);

    try {
      const result = await getAllWorkflows();
      const data = result.data;
      const workflows = data.map((workflow) => ({
        ...workflow,
      }));
      setWorkflows(workflows);
    } catch (e) {
      ToastError({
        title: 'Erro!',
        text: 'Erro ao listar fluxos de trabalho. Contate o administrador',
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const updatedCards = [...workflows];
    const [movedCard] = updatedCards.splice(dragIndex, 1);
    updatedCards.splice(hoverIndex, 0, movedCard);
    setWorkflows(updatedCards);
  };

  const handleBack = () => {
    if (newWorkflow) {
      setNewWorkflow(false);
    } else {
      navigate('/minhas-equipes');
    }
  };

  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <SectionHeader
              title="E-fluxo"
              subtitle="Estamos quase lá! Agora você pode criar seus fluxos"
            />
            <Box
              component="section"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="30px"
            >
              <WorkflowCard
                key="card-mais"
                workflow={{icon: 'mais', title: 'Novo fluxo'}}
                onClick={() => navigate('/adicionar-fluxo')}
              />
              {workflows.map((item, index) => (
                <CardDraggableComponent
                  key={item.id}
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  index={index}
                  moveCard={moveCard}
                  type={CARD_TYPE_WORKFLOW}
                />
              ))}
            </Box>
          </div>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            paddingY="10px"
            marginBottom="40px"
          >
            <Button
              component="footer"
              variant="outlined"
              sx={{
                borderColor: 'rgba(0, 21, 42, 0.5)',
                padding: '14px 22px',
                fontSize: '18px',
              }}
              onClick={handleBack}
            >
              Configurar depois
            </Button>
          </Box>
        </Box>
        <WorkflowChoice />
      </Box>
    </>
  );
};

export default WorkflowScreen;
