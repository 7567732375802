import React from 'react';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

interface FirstAccessFooterProps {
  rightButtonText: string;
  leftButtonText: string;
  rightButtonRoute: string;
  leftButtonRoute: string;
}

const FirstAccessFooter: React.FC<FirstAccessFooterProps> = ({
  rightButtonText,
  leftButtonText,
  rightButtonRoute,
  leftButtonRoute,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      component="footer"
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      paddingY="10px"
      marginBottom="40px"
    >
      <Button
        variant="outlined"
        sx={{
          borderColor: 'rgba(0, 21, 42, 0.5)',
        }}
        onClick={() => navigate(leftButtonRoute)}
      >
        {leftButtonText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(rightButtonRoute)}
      >
        {rightButtonText}
      </Button>
    </Box>
  );
};

export default FirstAccessFooter;
