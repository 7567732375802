import {ptBR} from 'date-fns/locale';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Popper} from '@mui/material';
import {Input, FormContainer} from '@/components/index';
import './index.css';
import {CalendarMonth} from '@mui/icons-material';

interface PropsInput {
  value?: Date;
  onChange?: any;
  title?: string;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

export default function DatePickerEA({
  value,
  onChange,
  title = 'Data',
  onEdit,
  onCopy,
  onDelete,
}: PropsInput) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <FormContainer
        title={title}
        onEdit={onEdit}
        onCopy={onCopy}
        onDelete={onDelete}
      >
        <DatePicker
          sx={{
            width: '100%',
            padding: '0px',
            '.MuiInputBase-root': {
              fontSize: '10px',
            },
            '.MuiSvgIcon-root': {
              fontSize: '16px',
            },
          }}
          value={value}
          onChange={onChange}
          slots={{
            popper: (props) => (
              <Popper
                {...props}
                sx={{
                  zIndex: 9999,
                  '.MuiPickersDay-root.Mui-selected': {
                    backgroundColor: '#003E7C !important',
                    color: 'white',
                  },
                  '.MuiPickersDay-root.Mui-selected:hover': {
                    backgroundColor: '#EEFBFF !important',
                    color: '#000',
                  },
                }}
              />
            ),
            openPickerIcon: CalendarMonth,
          }}
          slotProps={{
            openPickerButton: {
              color: 'warning',
            },
          }}
        />
      </FormContainer>
    </LocalizationProvider>
  );
}
