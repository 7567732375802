import {Box} from '@mui/material';
import './index.css';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../icon';

interface PropsInput {
  title: string;
  text: string;
}
import {Slide, toast} from 'react-toastify';

const CloseButton = ({closeToast}) => (
  <>
    <Box mt="12px" mr="12px">
      <Icon
        faIcon={{prefix: 'fas', iconName: 'times'}}
        color="#000"
        width={20}
        height={20}
        onClick={closeToast}
      />
    </Box>
  </>
);

export const ToastError = ({title, text}: PropsInput) => {
  return toast(<ToastErrorProps title={title} text={text} />, {
    hideProgressBar: true,
    transition: Slide,
    style: {
      border: '1px solid #FF3857',
    },
    closeButton: (e) => CloseButton(e),
  });
};

export default function ToastErrorProps({title, text}: PropsInput) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '300px',
          height: '99px',
          background: '#FFFCFC',
          paddingTop: '10px',
          paddingLeft: '14px',
          borderLeft: '9px solid #FF3857',
        }}
      >
        <Icon
          faIcon={{prefix: 'fas', iconName: 'times'}}
          width={24}
          height={24}
          color="#FF3857"
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Box className="toast-title">{title}</Box>
          <Box className="toast-sub-text">{text}</Box>
        </div>
      </div>
    </>
  );
}
