import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {useDrag} from 'react-dnd';
import {ItemTypes} from '@/types/ItemTypes';
import {EmojiIcon} from '@/components/shared';
import {Emoji} from '@/components/shared/emoji/emoji.tsx';

interface DraggableComponentProps {
  id: string;
  label: string;
  emoji: any;
  component: React.ElementType;
  onSelect: (component: React.ElementType) => void;
  colSpan: number;
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  id,
  label,
  emoji,
  component,
  onSelect,
  colSpan,
}) => {
  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.COMPONENT,
    item: {type: component, label, colSpan},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={drag}
      display="flex"
      flexDirection="row"
      gap="8px"
      alignItems="center"
      sx={{
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {label !== 'Adicionar seção' ? (
        <>
          <EmojiIcon emoji={emoji} width="14px" />
          <Typography variant="body2">{label}</Typography>
        </>
      ) : (
        <Box gap="10px" display="flex" flexDirection="column">
          <Divider />
          <Box display="flex" flexDirection="row" gap="8px">
            <EmojiIcon emoji={Emoji.Mais} width="14px" />
            <Typography variant="body2">Adicionar seção</Typography>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default DraggableComponent;
