import React, {useState} from 'react';
import {Box} from '@mui/material';
import {
  SectionHeader,
  Checklist,
  CardDraggableComponent,
  FirstAccessFooter,
} from '@/components/index';
import WorkflowCard from '@/pages/workflow/components/workflowCard';
import {useNavigate} from 'react-router-dom';

const menuExample = [
  {id: '1', title: 'Marketing', icon: 'check'},
  {id: '2', title: 'Comercial', icon: 'warning'},
  {id: '3', title: 'Suporte', icon: 'trophy'},
];

const TeamsScreen: React.FC = () => {
  const [newTeam, setNewTeam] = useState<boolean>(false);
  const [cards, setCards] = useState(menuExample);
  const CARD_TYPE_TEAM = 'CARD_TYPE_TEAM';
  const navigate = useNavigate();

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const updatedCards = [...cards];
    const [movedCard] = updatedCards.splice(dragIndex, 1);
    updatedCards.splice(hoverIndex, 0, movedCard);
    setCards(updatedCards);
  };

  const handleBack = () => {
    if (newTeam) {
      setNewTeam(false);
    } else {
      navigate('/minhas-empresas');
    }
  };

  return (
    <>
      <Box
        component="main"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flex="1"
          marginRight="40px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <SectionHeader
              title="Criar equipes"
              subtitle="As equipes são os setores que você segrega a sua empresa, por exemplo: marketing, vendas, compras...etc"
            />
            <Box
              component="section"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="30px"
            >
              <WorkflowCard
                key="card-mais"
                workflow={{icon: 'mais', title: 'Criar equipe'}}
                onClick={() => navigate('/adicionar-equipe')}
              />
              {cards.map((item, index) => (
                <CardDraggableComponent
                  type={CARD_TYPE_TEAM}
                  key={item.id}
                  id={item.id}
                  icon={item.icon}
                  title={item.title}
                  index={index}
                  moveCard={moveCard}
                  route="/adicionar-equipe"
                />
              ))}
            </Box>
          </div>

          <FirstAccessFooter
            leftButtonRoute="/minhas-empresas"
            leftButtonText="Voltar"
            rightButtonRoute="/meus-membros"
            rightButtonText="Salvar"
          />
        </Box>
        <Checklist active="/minhas-equipes" />
      </Box>
    </>
  );
};

export default TeamsScreen;
