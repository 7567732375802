import React from 'react';
import {Box, Link as MuiLink} from '@mui/material';
import './index.css';
import Rocket from '@/assets/svg/rocket.svg';
import {Link as ReactRouterLink} from 'react-router-dom';
import {AnimatedMenuItem2} from '@/components/Sidebar/AnimatedMenuItem/index-2.tsx';
import EaquiSvg from '@/assets/svg/sidebar/Eaqui.svg';
import EcontSvg from '@/assets/svg/sidebar/Econt.svg';
import ElucroSvg from '@/assets/svg/sidebar/Elucro.svg';
import InboxSvg from '@/assets/svg/sidebar/Inbox.svg';

const sidebarItens = [
  {
    id: '1',
    title: 'Caixa de entrada',
    icon: InboxSvg,
  },
  {
    id: '2',
    title: 'E-Cont',
    icon: EcontSvg,
  },
  {
    id: '3',
    title: 'E-Lucro',
    icon: ElucroSvg,
  },
  {
    id: '4',
    title: 'E-Aqui',
    icon: EaquiSvg,
  },
];

const Sidebar: React.FC = () => {
  return (
    <Box className="side-bar">
      <Box className="container-side-bar">
        <div className="first-container">
          <Box className="container-image">
            <img
              style={{display: 'block', width: '100%'}}
              src={Rocket}
              alt="Foguete"
            />
          </Box>

          {sidebarItens.map((item) => (
            <Box key={item.id}>
              <MuiLink component={ReactRouterLink} to="/workflow">
                <AnimatedMenuItem2 text={item.title} icon={item.icon} />
              </MuiLink>
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default Sidebar;
