import React, {useRef} from 'react';
import {useDrag} from 'react-dnd';

export const KanbanItem = ({id, children}) => {
  const ref = useRef(null);
  const [{isDragging}, drag] = useDrag({
    type: 'card',
    item: () => {
      return {id};
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(ref);
  return (
    <div ref={ref} style={{opacity}}>
      {children}
    </div>
  );
};