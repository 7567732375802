import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

type InterfaceProps = {
  width: number;
  height: number;
  faIcon: Partial<IconDefinition>;
  color?: string;
  background?: string;
  padding?: number;
  borderradius?: string;
  opacity?: number;
  onClick?: any;
  cursor?: string;
};

function Icon({
  color,
  background,
  padding,
  width,
  height,
  faIcon,
  borderradius,
  opacity,
  onClick,
  cursor = 'pointer',
}: InterfaceProps) {
  return (
    <>
      <i
        style={{
          color,
          background,
          padding,
          fontSize: `${height}px`,
          borderRadius: borderradius,
          opacity,
          cursor,
        }}
        className={`${faIcon?.prefix || 'fas'} fa-${faIcon.iconName}`}
        onClick={onClick}
      ></i>
    </>
  );
}

export default Icon;
