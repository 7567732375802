import * as React from "react";
const SvgMais = (props) => /* @__PURE__ */ React.createElement("svg", { width: 54, height: 49.310547, viewBox: "0 0 54 49.310547", fill: "#ff6407", id: "svg1", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs1" }), /* @__PURE__ */ React.createElement("g", { id: "path1" }, /* @__PURE__ */ React.createElement("path", { style: {
  color: "#000000",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  InkscapeStroke: "none"
}, d: "M 27.2233,4 V 45.3107 M 4,24.9903 h 46", id: "path2" }), /* @__PURE__ */ React.createElement("path", { style: {
  color: "#000000",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  InkscapeStroke: "none"
}, d: "m 27.222656,0 a 4,4 0 0 0 -4,4 V 20.990234 H 4 a 4,4 0 0 0 -4,4 4,4 0 0 0 4,4 h 19.222656 v 16.320313 a 4,4 0 0 0 4,4 4,4 0 0 0 4,-4 V 28.990234 H 50 a 4,4 0 0 0 4,-4 4,4 0 0 0 -4,-4 H 31.222656 V 4 a 4,4 0 0 0 -4,-4 z", id: "path3" })));
export default SvgMais;
