import Text from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/text.tsx';
import LongText from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/longText.tsx';
import Date from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/date.tsx';
import DateTime from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/dateTime.tsx';
import Time from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/time.tsx';
import Number from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/number.tsx';
import Checkbox from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/checkbox.tsx';
import Select from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/select.tsx';
import MultiSelect from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/multiSelect.tsx';
import Email from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/email.tsx';
import Link from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/link.tsx';
import PhoneNumber from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/phoneNumber.tsx';
import File from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/file.tsx';
import Cpf from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/cpf.tsx';
import Cnpj from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/cnpj.tsx';
import CpfCnpj from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/cpfCnpj.tsx';
import Currency from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/currency.tsx';
import Header from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/header.tsx';
import Paragraph from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/types/paragraph.tsx';
import {WorkflowInputTypeEnum} from '@/models/workflow-input-type.enum.ts';
import {
  IWorkflowInputType,
} from '@/pages/workflow/kanban/components/workflowKanbanCard/workflowInputType/IWorkflowInputType.tsx';

export const EnumToType = (enumValue: WorkflowInputTypeEnum): IWorkflowInputType => {
  switch (enumValue){
    case WorkflowInputTypeEnum.Text:
      return Text
    case WorkflowInputTypeEnum.LongText:
      return LongText;
    case WorkflowInputTypeEnum.Date:
      return Date;
    case WorkflowInputTypeEnum.DateTime:
      return DateTime;
    case WorkflowInputTypeEnum.Time:
      return Time;
    case WorkflowInputTypeEnum.Number:
      return Number;
    case WorkflowInputTypeEnum.Checkbox:
      return Checkbox;
    case WorkflowInputTypeEnum.Select:
      return Select;
    case WorkflowInputTypeEnum.SelectMultiple:
      return MultiSelect;
    case WorkflowInputTypeEnum.Email:
      return Email;
    case WorkflowInputTypeEnum.Link:
      return Link;
    case WorkflowInputTypeEnum.PhoneNumber:
      return PhoneNumber;
    case WorkflowInputTypeEnum.File:
      return File;
    case WorkflowInputTypeEnum.Cpf:
      return Cpf;
    case WorkflowInputTypeEnum.Cnpj:
      return Cnpj;
    case WorkflowInputTypeEnum.CpfCnpj:
      return CpfCnpj;
    case WorkflowInputTypeEnum.Currency:
      return Currency;
    case WorkflowInputTypeEnum.Header:
      return Header;
    case WorkflowInputTypeEnum.Paragraph:
      return Paragraph;

  }
}

export {
  Text,
  LongText,
  Date,
  DateTime,
  Time,
  Number,
  Checkbox,
  Select,
  MultiSelect,
  Email,
  Link,
  PhoneNumber,
  File,
  Cpf,
  Cnpj,
  CpfCnpj,
  Currency,
  Header,
  Paragraph,
}