import React, {useState} from 'react';
import {Box, Typography, Checkbox} from '@mui/material';
import ArrowSelect from '@/assets/icons/tableIcon/ArrowSelect.svg?react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import './MembersSelect.css';

interface Item {
  label: string;
}

interface TableSelectProps {
  items?: Item[];
  title?: string;
  checkbox?: boolean;
}

const MembersSelect: React.FC<TableSelectProps> = ({
  items,
  title,
  checkbox,
}) => {
  const [profile, setProfile] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option: string) => {
    setProfile(option);
    setIsOpen(false);
  };

  return (
    <Box>
      <Typography fontWeight="500" fontSize="14px">
        {title}
      </Typography>
      <div className="custom-select-wrapper" onClick={() => setIsOpen(!isOpen)}>
        <div className="custom-select">
          <Typography>{profile ? profile : 'Selecione'}</Typography>

          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            className="chevron-icon"
            style={{color: 'rgba(77, 81, 86, 1)'}}
          />
        </div>
        {isOpen && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box position="absolute" bottom={-16}>
              <ArrowSelect />
            </Box>
            <Box className="custom-select-options">
              {items.map((option, index) => (
                <Box
                  key={index}
                  display="flex"
                  gap="10px"
                  flexDirection="row"
                  alignItems="center"
                  className="custom-select-option"
                  onClick={() => handleSelectOption(option.label)}
                >
                  {checkbox && (
                    <Checkbox sx={{padding: '0px', margin: '0px'}} />
                  )}
                  <Typography
                    sx={{
                      color: 'rgba(0, 21, 42, 1)',
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    {option.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default MembersSelect;
