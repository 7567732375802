import React, {useState, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCheckCircle,
  faClock,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import './Select.css';

interface Item {
  label: string;
  icon: string;
  color: string;
}

interface TableSelectProps {
  items?: Item[];
  value?: string;
  title: string;
}

const Select: React.FC<TableSelectProps> = ({items, title, value}) => {
  const [profile, setProfile] = useState<string>(value || 'ativo');
  const [selectedIcon, setSelectedIcon] = useState<string>('checkCircle');
  const [iconColor, setIconColor] = useState<string>('green'); // Defina a cor padrão como verde
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setProfile(value || 'ativo');
    setSelectedIcon('checkCircle');
    setIconColor('green'); // Defina a cor inicial como verde
  }, [value]);

  const handleSelectOption = (option: string, icon: string, color: string) => {
    setProfile(option);
    setSelectedIcon(icon);
    setIconColor(color); // Atualize a cor do ícone com base na opção selecionada
    setIsOpen(false);
  };

  const getIcon = (iconName: string) => {
    const iconsMap: {[key: string]: any} = {
      checkCircle: faCheckCircle,
      clock: faClock,
      timesCircle: faTimesCircle,
    };
    return iconsMap[iconName] || faCheckCircle;
  };

  return (
    <Box
      onClick={() => setIsOpen(!isOpen)}
      sx={{cursor: 'pointer', position: 'relative'}}
    >
      <Typography fontWeight="700" fontSize="14px">
        {title}
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="250px"
        minWidth="200px"
        sx={{
          backgroundColor: '#ffffff',
          padding: '12px 16px',
          borderRadius: '3px',
          border: '1px solid rgba(0, 39, 78, 0.5)',
        }}
      >
        <Box display="flex" alignItems="center">
          <FontAwesomeIcon
            icon={getIcon(selectedIcon)}
            style={{color: iconColor}} // Use o estado iconColor para definir a cor do ícone
          />
          <Typography
            sx={{marginLeft: '8px'}}
            color="rgba(0, 0, 0, 1)"
            fontWeight="400"
            fontSize="16px"
          >
            {profile ? profile : 'Selecione'}
          </Typography>
        </Box>
        <Box sx={{ml: 2}}>
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            style={{color: 'rgba(77, 81, 86, 1)'}}
          />
        </Box>
      </Box>

      {isOpen && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box className="select-options">
            {items?.map((option, index) => (
              <Box
                key={index}
                display="flex"
                gap="10px"
                flexDirection="row"
                alignItems="center"
                className="select-option"
                onClick={() =>
                  handleSelectOption(option.label, option.icon, option.color)
                }
              >
                <FontAwesomeIcon
                  icon={getIcon(option.icon)}
                  style={{color: option.color}}
                />
                <Typography
                  sx={{
                    color: 'rgba(0, 21, 42, 1)',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Select;
