import './App.css';
import {Outlet} from 'react-router-dom';
import {theme} from './components/theme';
import {ThemeProvider} from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {Sidebar, Container, Header} from '@/components/index';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {useLocation} from 'react-router-dom';

function App() {
  const {pathname} = useLocation();

  return (
    <>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          {pathname !== '/primeiro-acesso' && <Header />}

          <Sidebar />
          <Container>
            <Outlet />
          </Container>
          <ToastContainer />
        </DndProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
