import {faPhone} from '@fortawesome/pro-solid-svg-icons';

const Input = () => {
  return <></>;
};

const CardIcon = faPhone;

export default {
  CardIcon,
  Input,
};
