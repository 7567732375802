import React from 'react';
import {Card, CardContent, Typography, Box} from '@mui/material';
import {EmojiIcon} from '@/components/shared';
import {WorkflowDto} from '@/models/workflow.dto.ts';
import {faGrid2} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './TeamCardMenu.css';

interface TeamCardMenuProps {
  onClick?: () => void;
  workflow: WorkflowDto;
  type?: string;
}

const TeamCardMenu: React.FC<TeamCardMenuProps> = ({
  onClick,
  workflow,
  type,
}) => {
  const animatedClass = onClick ? 'animated' : '';
  const isMoreIcon = workflow.icon === 'mais';

  return (
    <Card
      className={`team-card-menu ${animatedClass}`}
      onClick={onClick}
      sx={{
        position: 'relative',
        '&:hover .icon-box': {
          opacity: 1,
        },
      }}
      aria-label={workflow.title}
    >
      {!isMoreIcon && (
        <Box
          className="icon-box"
          width="100%"
          sx={{
            position: 'absolute',
            top: 10,
            left: -40,
            opacity: 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          <FontAwesomeIcon
            icon={faGrid2}
            style={{
              cursor: 'pointer',
              height: '13px',
              color: 'rgba(0, 39, 78, 1)',
            }}
          />
        </Box>
      )}

      <CardContent className="team-card-container">
        <EmojiIcon emoji={workflow.icon} />
        <Typography
          variant="body1"
          align="center"
          fontSize="10px"
          fontWeight="600"
        >
          {workflow.title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TeamCardMenu;
