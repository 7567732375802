import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {ItemTypes} from '@/types/ItemTypes';
import WorkflowCard from '@/pages/workflow/components/workflowCard';
import {CardPlaceholder, TeamCardMenu} from '@/components/index';
import {useNavigate} from 'react-router';

interface DraggableCardProps {
  id: string;
  icon: string;
  title: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  type: string;
  route?: string;
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  id,
  icon,
  title,
  index,
  moveCard,
  type,
  route,
}) => {
  const navigate = useNavigate();
  const [{isDragging}, drag] = useDrag({
    type: type,
    item: {id, index},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: type,
    hover: (item: {index: number}) => {
      if (item.index !== index) {
        moveCard(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))}>
      {isDragging ? (
        <CardPlaceholder isActive={isDragging} type={type} />
      ) : type === 'CARD_TYPE_WORKFLOW_CHOICE' ? (
        <div>
          <TeamCardMenu workflow={{icon, title}} />
        </div>
      ) : (
        <div>
          <WorkflowCard
            workflow={{icon, title, id}}
            onClick={() => navigate(route ? route : `/workflow/${id}/kanban`)}
          />
        </div>
      )}
    </div>
  );
};

export default DraggableCard;
