import React, {useState} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import './FormHeader.css';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {useNavigate, useLocation} from 'react-router-dom';

const formMenuItems = [
  {id: 1, label: 'Personalizar'},
  {id: 2, label: 'Etapas'},
  {id: 3, label: 'Formulário'},
];

const FormHeader: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState(1);

  const {pathname} = useLocation();
  const navigate = useNavigate();

  const handleMenuClick = (index) => {
    setActiveMenu(index);
  };

  const handleClose = () => {
    navigate('/workflow');
  };

  return (
    <Box className="form-header-container">
      {formMenuItems.map((item) => (
        <Typography
          key={item.id}
          className={`form-header-item ${activeMenu === item.id && 'active'}`}
          onClick={() => handleMenuClick(item.id)}
        >
          {item.label}
        </Typography>
      ))}
      <Box position="fixed" right={2} top={2}>
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FormHeader;
