import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import ChecklistSvg from '@/assets/svg/Checklist.svg';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const steps = [
  {
    id: '1',
    title: 'Cadastrar empresa',
    route: '/minhas-empresas',
  },
  {
    id: '2',
    title: 'Criar equipes',
    route: '/minhas-equipes',
  },
  {
    id: '3',
    title: 'Convidar membros',
    route: '/meus-membros',
  },
  {
    id: '4',
    title: 'Configurar fluxos',
    route: '/meus-fluxos',
  },
];

interface ChecklistProps {
  active: string;
}

const Checklist: React.FC<ChecklistProps> = ({active}) => {
  const getIcon = (itemRoute: any) => {
    const itemIndex = steps.findIndex((step) => step.route === itemRoute);
    const activeIndex = steps.findIndex((step) => step.route === active);

    if (itemIndex < activeIndex) {
      // Etapas já completadas
      return <CheckCircleRoundedIcon color="success" fontSize="medium" />;
    } else if (itemIndex === activeIndex) {
      // Etapa ativa
      return (
        <RadioButtonUncheckedRoundedIcon
          sx={{color: 'rgba(77, 81, 86, 1)'}}
          fontSize="medium"
        />
      );
    } else {
      // Etapas ainda não completadas
      return (
        <RadioButtonUncheckedRoundedIcon
          sx={{color: 'rgba(77, 81, 86, 0.5)'}}
          fontSize="medium"
        />
      );
    }
  };

  const getTitleColor = (itemRoute: string) => {
    const itemIndex = steps.findIndex((step) => step.route === itemRoute);
    const activeIndex = steps.findIndex((step) => step.route === active);

    if (itemIndex <= activeIndex) {
      // Título para etapas já completadas ou a etapa atual
      return 'rgba(0, 21, 42, 1)';
    } else {
      // Título para etapas ainda não completadas
      return 'rgba(77, 81, 86, 0.5)';
    }
  };

  return (
    <Box
      display="flex"
      component="section"
      flexDirection="column"
      maxWidth="255px"
      height="calc(100vh - 60px)"
      sx={{
        borderLeft: '1px solid rgba(0, 39, 78, 0.2)',
      }}
    >
      <img src={ChecklistSvg} alt="Checklist" />
      <Box padding="30px">
        <Typography
          textAlign="center"
          marginBottom="15px"
          fontSize="20px"
          fontWeight="700px"
        >
          Checklist
        </Typography>
        <Divider />
        <Typography marginY="30px" fontSize="12px" fontWeight="500">
          Confira nosso checklist antes de configurar seus fluxos
        </Typography>
        {steps.map((item) => (
          <Box
            key={item.id}
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
            marginBottom="15px"
          >
            {getIcon(item.route)}
            <Typography
              component="p"
              fontSize="12px"
              fontWeight="500"
              sx={{color: getTitleColor(item.route)}}
            >
              {item.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Checklist;
